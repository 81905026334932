import { useModel as _useModel, useCssVars as _useCssVars, mergeModels as _mergeModels, defineComponent as _defineComponent } from 'vue'
import { renderSlot as _renderSlot, unref as _unref, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createBlock as _createBlock, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, Fragment as _Fragment, vShow as _vShow, withDirectives as _withDirectives, Transition as _Transition, createVNode as _createVNode, normalizeClass as _normalizeClass, Teleport as _Teleport } from "vue"

const _hoisted_1 = { class: "sm-modal__window" }
const _hoisted_2 = {
  key: 0,
  class: "sm-modal__header"
}
const _hoisted_3 = { class: "sm-modal__header-wrap" }
const _hoisted_4 = {
  key: 0,
  class: "sm-modal__title"
}
const _hoisted_5 = { class: "sm-modal__body" }
const _hoisted_6 = {
  key: 1,
  class: "sm-modal__footer"
}
const _hoisted_7 = { class: "sm-modal__footer-wrap" }

import { useSlots, computed, ref, toRefs } from 'vue';

// Components
import SmIcon from '@/components/common/SmIcon/SmIcon.vue';
import SmButton from '@/components/common/buttons/SmButton.vue';

// Types
import { IModalProps } from '@/types/components/modal';
import { Class } from '@/types/common';

// Composables


export default /*@__PURE__*/_defineComponent({
  __name: 'SmModal',
  props: /*@__PURE__*/_mergeModels({
    title: { default: '' },
    width: { default: '402px' },
    height: { default: 'auto' },
    maxWidth: { default: 'initial' },
    maxHeight: { default: 'initial' },
    cancebleBackdrop: { type: Boolean, default: true },
    showCloseIcon: { type: Boolean, default: true },
    isLeftButton: { type: Boolean, default: true },
    isRightButton: { type: Boolean, default: true },
    leftButtonText: { default: 'Отмена' },
    rightButtonText: { default: 'Принять' },
    leftButtonType: { default: 'neutral' },
    rightButtonType: { default: 'primary' },
    isLeftButtonDisabled: { type: Boolean, default: false },
    isRightButtonDisabled: { type: Boolean, default: false }
  }, {
    "modelValue": { type: Boolean },
    "modelModifiers": {},
  }),
  emits: /*@__PURE__*/_mergeModels(["update:modelValue", "open", "openned", "close", "closed", "leftButtonClick", "rightButtonClick"], ["update:modelValue"]),
  setup(__props: any, { emit: __emit }) {

_useCssVars(_ctx => ({
  "8de9f662": (_unref(width)),
  "4e869b3e": (_unref(height)),
  "13d13719": (_unref(maxWidth)),
  "4c86b234": (_unref(maxHeight))
}))

// Modules
const { header, footer }: any = useSlots();

// Model

const modelValue = _useModel<boolean>(__props, "modelValue");

// Props

const props = __props;

const {
  title,
  width,
  height,
  maxWidth,
  maxHeight,
  cancebleBackdrop,
  showCloseIcon,
  isLeftButton,
  isRightButton,
  leftButtonText,
  rightButtonText,
  leftButtonType,
  rightButtonType,
  isLeftButtonDisabled,
  isRightButtonDisabled,
} = toRefs(props);

// Emits

const emits = __emit;

// Data

const showModalWindow = ref<boolean>(false);

// Computed

const classList = computed((): Class => ([
  { 'sm-modal--closable-backdrop': cancebleBackdrop.value }
]));

const showHeader = computed((): boolean => (
  header || title
));

const showFooter = computed((): boolean => (
  footer || isFooterButtons.value
));

const isFooterButtons = computed((): boolean => (
  isLeftButton.value || isRightButton.value
));

// Methods

const handleCloseByBackdrop = (): void => {
  if (!cancebleBackdrop.value) return;

  closeModal();
}

const handleClose = (): void => {
  closeModal();
}

// Transition methods
const handleBeforeEnter = (): void => {
  emits('open');
}
const handleAfterEnter = (): void => {
  showModalWindow.value = true;
  emits('openned');
}
const handleBeforeLeave = (): void => {
  showModalWindow.value = false;
  emits('close');
}
const handleAfterLeave = (): void => {
  emits('closed');
}

const closeModal = (): void => {
  emits('update:modelValue', false);
}

const handleClickLeftButton = (): void => {
  emits('leftButtonClick');
}

const handleClickRightButton = (): void => {
  emits('rightButtonClick');
}

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createBlock(_Teleport, { to: "body" }, [
    _createVNode(_Transition, {
      name: "sm-modal__animation",
      onBeforeEnter: handleBeforeEnter,
      onAfterEnter: handleAfterEnter,
      onBeforeLeave: handleBeforeLeave,
      onAfterLeave: handleAfterLeave
    }, {
      default: _withCtx(() => [
        _withDirectives(_createElementVNode("div", {
          class: _normalizeClass(["sm-modal", classList.value]),
          onClick: handleCloseByBackdrop
        }, [
          _createVNode(_Transition, { name: "sm-modal__animation-window" }, {
            default: _withCtx(() => [
              _withDirectives(_createElementVNode("div", _hoisted_1, [
                (showHeader.value)
                  ? (_openBlock(), _createElementBlock("header", _hoisted_2, [
                      _renderSlot(_ctx.$slots, "header", {}, () => [
                        _createElementVNode("div", _hoisted_3, [
                          (_unref(title))
                            ? (_openBlock(), _createElementBlock("p", _hoisted_4, _toDisplayString(_unref(title)), 1))
                            : _createCommentVNode("", true),
                          (_unref(showCloseIcon))
                            ? (_openBlock(), _createBlock(SmIcon, {
                                key: 1,
                                name: "Cancel",
                                size: 24,
                                class: "sm-modal__close",
                                onClick: handleClose
                              }))
                            : _createCommentVNode("", true)
                        ])
                      ])
                    ]))
                  : _createCommentVNode("", true),
                _createElementVNode("section", _hoisted_5, [
                  _renderSlot(_ctx.$slots, "default")
                ]),
                (showFooter.value)
                  ? (_openBlock(), _createElementBlock("footer", _hoisted_6, [
                      _createElementVNode("div", _hoisted_7, [
                        _renderSlot(_ctx.$slots, "footer", {}, () => [
                          (isFooterButtons.value)
                            ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                                (_unref(isLeftButton))
                                  ? (_openBlock(), _createBlock(SmButton, {
                                      key: 0,
                                      type: _unref(leftButtonType),
                                      disabled: _unref(isLeftButtonDisabled),
                                      onClick: handleClickLeftButton
                                    }, {
                                      default: _withCtx(() => [
                                        _createTextVNode(_toDisplayString(_unref(leftButtonText)), 1)
                                      ]),
                                      _: 1
                                    }, 8, ["type", "disabled"]))
                                  : _createCommentVNode("", true),
                                (_unref(isRightButton))
                                  ? (_openBlock(), _createBlock(SmButton, {
                                      key: 1,
                                      type: _unref(rightButtonType),
                                      disabled: _unref(isRightButtonDisabled),
                                      onClick: handleClickRightButton
                                    }, {
                                      default: _withCtx(() => [
                                        _createTextVNode(_toDisplayString(_unref(rightButtonText)), 1)
                                      ]),
                                      _: 1
                                    }, 8, ["type", "disabled"]))
                                  : _createCommentVNode("", true)
                              ], 64))
                            : _createCommentVNode("", true)
                        ])
                      ])
                    ]))
                  : _createCommentVNode("", true)
              ], 512), [
                [_vShow, showModalWindow.value]
              ])
            ]),
            _: 3
          })
        ], 2), [
          [_vShow, modelValue.value]
        ])
      ]),
      _: 3
    })
  ]))
}
}

})