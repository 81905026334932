import { getItemFromLocalStorage, getItemFromSessionStorage } from '@core/utils/persistenceStorage';
import { useBaseStore } from '~/stores/base';


function isStoreAvailable() {
  return !!useBaseStore();
}

export const getCurrentBase = () => {
  if (isStoreAvailable()) {
    return useBaseStore().baseId;
  }

  const isAdmin = getItemFromLocalStorage('isAdmin');

  if (isAdmin) {
    return getItemFromSessionStorage('base');
  }

  return getItemFromLocalStorage('base');
};

export const getCurrentBaseName = () => {
  if (isStoreAvailable()) {
    return useBaseStore().baseName;
  }

  const isAdmin = getItemFromLocalStorage('isAdmin');

  if (isAdmin) {
    return getItemFromSessionStorage('selectedBaseName');
  }

  return getItemFromLocalStorage('selectedBaseName');
};
