import categoriesListsItems from '~/constants/categoriesListsItems';

interface Permission {
  controller: string;
  method: string;
  action: string;
}

interface ListItem {
  route: { name: string };
  permission?: Permission;
}

interface CategoryItem {
  list: ListItem[];
}

interface Category {
  items: CategoryItem[];
}

// Проверяет, является ли элемент типа ListItem и имеет ли он свойство permission типа Permission
const hasPermission = (item: ListItem): item is ListItem & { permission: Permission } => {
  return !!item.permission;
};

export const findRequiredPermission = (routeName: string): Permission | null => {
  for (const category of Object.values(categoriesListsItems)) {
    const requiredPermission = findPermissionInCategory(category, routeName);
    if (requiredPermission !== null) {
      return requiredPermission;
    }
  }
  return null;
};

const findPermissionInCategory = (category: Category, routeName: string): Permission | null => {
  for (const item of category.items) {
    const listItem = item.list.find((listItem: ListItem) => listItem.route.name === routeName);
    if (listItem && hasPermission(listItem)) {
      return listItem.permission;
    }
  }
  return null;
};
