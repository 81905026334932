import { ICSSColorVariables } from '@/types/cssColorVariables'

export default <ICSSColorVariables>{
  light: {
    // Backgrounds
    Background: '#F6F6F6', // Основной цвет фона
    Surface: '#E0EFF1', // Поверхность

    // Text
    Title: '#262644', // Все заголовки
    OnSurfaceTitle: '#262644',
    Body: '#262644', // Основной наборный текст
    OnSurfaceBody: '#262644',
    Label: '#55586A', // Подписи, подсказки
    OnSurfaceLabel: '#55586A',
    Additional: '#7E8CA0', // Фоновый текст оформления
    OnSurfaceAdditional: '#7E8CA0',
    Disabled: '#DFDFDF', // Цвет подписей недоступных элементов
    OnSurfaceDisabled: '#D0D5E3',
    BodyInverse: '#F3F3F3', // Основной наборный текст
    OnSurfaceBodyInverse: '#F3F3F3',

    // Primary & secondary
    Primary: '#067A82', // Основной акцентный цвет бренда
    OnPrimary: '#FFFFFF', // Контент на primary цвете
    PrimaryHover: '#1A696F', // Primary кнопка в состоянии наведения
    PrimaryPress: '#425455', // Primary кнопка в состоянии нажатия
    PrimaryDisable: '#C5C5C5', // Primary кнопка в состоянии недоступном
    OnPrimaryDisable: '#E4E4E4', // Цвет контента на primary кнопках в состоянии недоступном
    Secondary: '#B1CBCE', // Дополнительный цвет бренда
    OnSecondary: '#262644', // Контент на secondary цвете
    SecondaryHover: '#9FC1C5', // Secondary кнопка в состоянии наведения
    SecondaryPress: '#82BDC3', // Secondary кнопка в состоянии нажатия
    SecondaryDisable: '#CAD4E4', // Secondary кнопка в состоянии недоступном
    OnSecondaryDisable: '#777793', // Цвет контента на secondary кнопке в состоянии недоступном

    // Accent
    Accent: '#B11010', // Акцентный цвет бренда
    OnAccent: '#FFFFFF', // Контент на акцентном цвете
    AccentDisable: '#C5C5C5', // Акцентная кнопка в состоянии недоступном
    OnAccentDisable: '#E4E4E4', // Цвет контента на акцентной кнопке в состоянии недоступном

    // Brand
    Major: '#067A82', // Основной цвет логотипа
    Minor: '#D32020', // Акцентный цвет логотипа

    // Graphic - Для графиков и рисунков
    A: '#FFC2C4',
    B: '#91CAC2',
    C: '#8494EB',
    D: '#F5DBA3',
    E: '#A698EB',
    F: '#747BAA',
    G: '#5EBDD3',
    H: '#EFBBDD',
    I: '#A4C9EA',
    J: '#C0E471',
    K: '#FA8F80',
    L: '#42BFE7',

    // Container & elements
    SecContainer: '#B1CBCE', // Чипсы
    OnSecContainer: '#262644', // Контент на sec-container цвете
    SecContainerHover: '#9FC1C5', // Элементы в состоянии наведения
    SecContainerPress: '#82BDC3', // Элементы в состоянии нажатия
    SecContainerDisable: '#CAD4E4', // Secondary кнопка в состоянии недоступном
    OnSecContainerDisable: '#A4A4D6', // Цвет контента на secondary кнопках в состоянии недоступном
    Elm95: '#F0F3F8',
    Elm90: '#E2E7EF',
    Elm80: '#CCD3DF',
    Elm70: '#B8C0CD',
    Elm60: '#A1ABBC',
    Elm50: '#8C98AC',
    Elm0: '#29303C',

    // Icon
    Icon: '#8D9DB0', // Основной цвет иконок
    IconHoverPress: '#005257', // Маленькие элементы в состоянии наведения. Это Primary
    IconLabel: '#ABB8C9', // Дополнительный цвет иконок
    IconDisable: '#D0D5E3', // Недоступные иконки

    // Border
    BorderMajor: '#A8B1CB', // Разделители
    BorderMinor: '#CDD3E4', // Разделители
    BorderHoverPress: '#005257', // Обводка для кнопок в состоянии наведения
    BorderDisable: '#D0D5E3', // Обводка для кнопок в состоянии недоступном

    // Selection
    SelectionPrimary: '#C7E0DFD9',
    SelectionPrimary2: '#C7E0DFA6',
    SelectionPrimary3: '#C7E0DF33',
    Highlight: '#BCDDE1',

    // Illustrations
    Brand1: '#AFD6AA', // Цвет используемый в иллюстрациях

    // Text semantic
    Success: '#076236', // Цвет статусов с флагом “Success”
    Neutral: '#000000', // Цвет статусов с флагом “Neutral”
    Info: '#054CB0', // Цвет статусов с флагом “Info”
    Error: '#A02123', // Цвет статусов с флагом “error”
    Warning: '#73500C', // Цвет статусов с флагом “warning”

    // Icon semantic
    IconSuccess: '#389E0D', // Цвет иконок статуса success
    IconError: '#E84749', // Цвет иконок статуса error
    IconInfo: '#1B73EE', // Цвет иконок статуса info
    IconWarning: '#F0BB00', // Цвет иконок статуса warning

    // Background semantic
    BGSuccess: '#EDFFF2', // Фон под группой элементов, отображающих успешный результат
    BGNeutral: '#FAFAFA', // Фон под группой элементов, отображающих нейтральное состояние
    BGInfo: '#E6F4FF', // Фон под группой элементов, отображающих информацию
    BGError: '#FFF2F0', // Фон под группой элементов, отображающих ошибку
    BGWarning: '#FFFBE6', // Фон под группой элементов, отображающих предупреждение

    // Stroke semantic
    StrokeSuccess: '#ADEBCD', // Обводка для алертов отображающих успешный результат
    StrokeNeutral: '#D9D9D9', // Обводка для алертов отображающих нейтральное состояние
    StrokeInfo: '#91CAFF', // Обводка для алертов отображающих информацию
    StrokeError: '#FFCCC7', // Обводка для алертов отображающих ошибку
    StrokeWarning: '#FFE58F', // Обводка для алертов отображающих предупреждение

    // Special
    ThemeButton: '#262644', // Цвет для кнопки переключающая тему
    OnThemeButton: '#FFF38E', // Цвет контента для кнопки переключающая тему

    // Shadow
    DropdownShadow: '21, 45, 91, 0.11',
    DropdownShadow2: '60, 80, 119, 0.08',
  },
  dark: {
    Background: '#0E262B',
    Surface: '#12333A',

    // Text
    Title: '#DEE4E4', // Все заголовки
    OnSurfaceTitle: '#DEE4E4',
    Body: '#DEE4E4', // Основной наборный текст
    OnSurfaceBody: '#DEE4E4',
    Label: '#8AA0A0', // Подписи, подсказки
    OnSurfaceLabel: '#8AA0A0',
    Additional: '#5E7A77', // Фоновый текст оформления
    OnSurfaceAdditional: '#5E7A77',
    Disabled: '#253F3C', // Цвет подписей недоступных элементов
    OnSurfaceDisabled: '#314C52',
    BodyInverse: '#262644', // Основной наборный текст
    OnSurfaceBodyInverse: '#262644',
    
    // Primary & secondary
    Primary: '#80D4DC', // Основной акцентный цвет бренда
    OnPrimary: '#00363A', // Контент на primary цвете
    PrimaryHover: '#5EC1CA', // Primary кнопка в состоянии наведения
    PrimaryPress: '#41A5AF', // Primary кнопка в состоянии нажатия
    PrimaryDisable: '#313C3D', // Primary кнопка в состоянии недоступном
    OnPrimaryDisable: '#465A5B', // Цвет контента на primary кнопках в состоянии недоступном
    Secondary: '#B1CBCE', // Дополнительный цвет бренда
    OnSecondary: '#1B3437', // Контент на secondary цвете
    SecondaryHover: '#9FC1C5', // Secondary кнопка в состоянии наведения
    SecondaryPress: '#82BDC3', // Secondary кнопка в состоянии нажатия
    SecondaryDisable: '#203640', // Secondary кнопка в состоянии недоступном
    OnSecondaryDisable: '#3F4E56', // Цвет контента на secondary кнопке в состоянии недоступном

    // Accent
    Accent: '#EF4E4E', // Акцентный цвет бренда
    OnAccent: '#062232', // Контент на акцентном цвете
    AccentDisable: '#313C3D', // Акцентная кнопка в состоянии недоступном
    OnAccentDisable: '#465A5B', // Цвет контента на акцентной кнопке в состоянии недоступном

    // Brand
    Major: '#80D4DC', // Основной цвет логотипа
    Minor: '#EF4E4E', // Акцентный цвет логотипа

    // Graphic - Для графиков и рисунков
    A: '#FFC2C4',
    B: '#91CAC2',
    C: '#8494EB',
    D: '#F5DBA3',
    E: '#A698EB',
    F: '#747BAA',
    G: '#5EBDD3',
    H: '#EFBBDD',
    I: '#A4C9EA',
    J: '#C0E471',
    K: '#FA8F80',
    L: '#42BFE7',

    // Container & elements
    SecContainer: '#324B4D', // Чипсы
    OnSecContainer: '#CCE8EA', // Контент на sec-container цвете
    SecContainerHover: '#1E4144', // Элементы в состоянии наведения
    SecContainerPress: '#073E45', // Элементы в состоянии нажатия
    SecContainerDisable: '#203640', // Secondary кнопка в состоянии недоступном
    OnSecContainerDisable: '#3F4E56', // Цвет контента на secondary кнопках в состоянии недоступном
    Elm95: '#020C08',
    Elm90: '#112F24',
    Elm80: '#26453A',
    Elm70: '#3C5C4F',
    Elm60: '#54756B',
    Elm50: '#6D8F7D',
    Elm0: '#F9F9FF',

    // Icon
    Icon: '#9BA8B5', // Основной цвет иконок
    IconHoverPress: '#5BA7AE', // Маленькие элементы в состоянии наведения. Это Primary
    IconLabel: '#748495', // Дополнительный цвет иконок
    IconDisable: '#253F3C', // Недоступные иконки

    // Border
    BorderMajor: '#4A6267', // Разделители
    BorderMinor: '#3F4C4F', // Разделители
    BorderHoverPress: '#5BA7AE', // Обводка для кнопок в состоянии наведения
    BorderDisable: '#253F3C', // Обводка для кнопок в состоянии недоступном

    // Selection
    SelectionPrimary: '#80D4DC1A',
    SelectionPrimary2: '#80D4DC33',
    SelectionPrimary3: '#80D4DC33',
    Highlight: '#0F80797D',

    // Illustrations
    Brand1: '#E4BFA8', // Цвет используемый в иллюстрациях

    // Text semantic
    Success: '#81D252', // Цвет статусов с флагом “Success”
    Neutral: '#DBDBDB', // Цвет статусов с флагом “Neutral”
    Info: '#397FE1', // Цвет статусов с флагом “Info”
    Error: '#E84749', // Цвет статусов с флагом “error”
    Warning: '#D89614', // Цвет статусов с флагом “warning”

    // Icon semantic
    IconSuccess: '#49AA19', // Цвет иконок статуса success
    IconError: '#E84749', // Цвет иконок статуса error
    IconInfo: '#3C89E8', // Цвет иконок статуса info
    IconWarning: '#E8B339', // Цвет иконок статуса warning

    // Background semantic
    BGSuccess: '#0E1D09', // Фон под группой элементов, отображающих успешный результат
    BGNeutral: '#1D1D1D', // Фон под группой элементов, отображающих нейтральное состояние
    BGInfo: '#091223', // Фон под группой элементов, отображающих информацию
    BGError: '#2F070C', // Фон под группой элементов, отображающих ошибку
    BGWarning: '#2B2111', // Фон под группой элементов, отображающих предупреждение

    // Stroke semantic
    StrokeSuccess: '#1C3C0D', // Обводка для алертов отображающих успешный результат
    StrokeNeutral: '#383838', // Обводка для алертов отображающих нейтральное состояние
    StrokeInfo: '#173B6D', // Обводка для алертов отображающих информацию
    StrokeError: '#5F1D21', // Обводка для алертов отображающих ошибку
    StrokeWarning: '#594214', // Обводка для алертов отображающих предупреждение

    // Special
    ThemeButton: '#DEE4E4', // Цвет для кнопки переключающая тему
    OnThemeButton: '#DDA411', // Цвет контента для кнопки переключающая тему

    // Shadow
    DropdownShadow: '21, 45, 91, 0.11',
    DropdownShadow2: '60, 80, 119, 0.08',
  }
}