import { defineComponent as _defineComponent } from 'vue'
import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, mergeProps as _mergeProps, createBlock as _createBlock, createElementVNode as _createElementVNode, Teleport as _Teleport } from "vue"

const _hoisted_1 = { class: "sm-notify-bar" }

import { ref, getCurrentInstance } from 'vue';
import SmNotify from './SmNotify.vue';
import { INotifyProps } from "@/types/components/notify";


export default /*@__PURE__*/_defineComponent({
  __name: 'SmNotifyBar',
  setup(__props) {

const { proxy }: any = getCurrentInstance();

const notifyList = ref<INotifyProps[]>(proxy.$notifyList.value);

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createBlock(_Teleport, { to: "body" }, [
    _createElementVNode("div", _hoisted_1, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(notifyList.value, (notify) => {
        return (_openBlock(), _createBlock(SmNotify, _mergeProps({
          key: notify.uuid,
          ref_for: true
        }, notify), null, 16))
      }), 128))
    ])
  ]))
}
}

})