import { IPermissions } from '../types/permissions';

class PermissionService {
  private permissions: IPermissions;

  constructor(permissions: IPermissions) {
    this.permissions = permissions;
  }

  hasAccess(controller: string, method: string, action: string): boolean {
    if (this.permissions.hasFullAccess) return true;

    if (!this.permissions.permissions) return false;

    const permissionString = `${controller}.${method}.${action}`;
    return this.permissions.permissions.includes(permissionString);
  }
}

export { PermissionService };