import { defineComponent as _defineComponent } from 'vue'
import { normalizeClass as _normalizeClass, createVNode as _createVNode, unref as _unref, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, vShow as _vShow, withDirectives as _withDirectives, Transition as _Transition, withCtx as _withCtx, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "sm-notify" }
const _hoisted_2 = { class: "sm-notify__header" }
const _hoisted_3 = {
  key: 0,
  class: "sm-notify__title"
}
const _hoisted_4 = { class: "sm-notify__text" }

import { ref, onMounted, toRefs, computed } from "vue";
// Types
import { INotifyProps } from "@/types/components/notify";
import { Class } from "@/types/common";
// Components
import SmIcon from '@/components/common/SmIcon/SmIcon.vue';


export default /*@__PURE__*/_defineComponent({
  __name: 'SmNotify',
  props: {
    uuid: {},
    type: { default: 'neutral' },
    title: { default: '' },
    text: {},
    delay: { default: 6000 }
  },
  setup(__props: any) {

// Modules
const props = __props;

const {
  type,
  title,
  text,
  delay,
} = toRefs(props);

const showNotify = ref<boolean>(false);

const iconNames:Record<string, string> = {
  success: 'Success',
  warning: 'Warning',
  error: 'Error',
  info: 'Info',
};

const iconName = computed((): string => iconNames[type.value] || iconNames.info);

const iconClassList = computed((): Class => {
  return `sm-notify__icon--${type.value}`
});

const contentClassList = computed((): Class => {
  return [
    'sm-notify__content',
    !title.value ? 'sm-notify__content--inline' : '',
  ]
  .filter(item => item);
});

const close = (): void => {
  showNotify.value = false;
}

onMounted(() => {
  showNotify.value = true;

  setTimeout(() => {
    close();
  }, delay.value);
});

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createBlock(_Transition, { name: "sm-notify-animate" }, {
    default: _withCtx(() => [
      _withDirectives(_createElementVNode("div", _hoisted_1, [
        _createVNode(SmIcon, {
          name: iconName.value,
          size: 24,
          class: _normalizeClass(["sm-notify__icon", iconClassList.value])
        }, null, 8, ["name", "class"]),
        _createElementVNode("div", {
          class: _normalizeClass(contentClassList.value)
        }, [
          _createElementVNode("header", _hoisted_2, [
            (_unref(title))
              ? (_openBlock(), _createElementBlock("h3", _hoisted_3, _toDisplayString(_unref(title)), 1))
              : _createCommentVNode("", true),
            _createVNode(SmIcon, {
              name: "Cancel",
              size: 16,
              class: "sm-notify__close",
              onClick: close
            })
          ]),
          _createElementVNode("p", _hoisted_4, _toDisplayString(_unref(text)), 1)
        ], 2)
      ], 512), [
        [_vShow, showNotify.value]
      ])
    ]),
    _: 1
  }))
}
}

})