import { defineComponent as _defineComponent } from 'vue'
import { resolveComponent as _resolveComponent, createVNode as _createVNode, resolveDynamicComponent as _resolveDynamicComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue"

import { computed } from 'vue';

// Composables
import { useRoute } from 'vue-router';

// Components
import SmNotifyBar from '@core/components/common/notify/SmNotifyBar.vue';

// Utils TODO: Исправить название функции
import { setCSSStyles } from '@core/utils/setCSSStyles';


export default /*@__PURE__*/_defineComponent({
  __name: 'App',
  setup(__props) {

// Vue moduls
const route = useRoute();
const layoutComponent = computed(() => route.meta.layoutComponent || null);

// Methods
const init = async () => {
  setCSSStyles();
}

// Init
init();

return (_ctx: any,_cache: any) => {
  const _component_router_view = _resolveComponent("router-view")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    (_openBlock(), _createBlock(_resolveDynamicComponent(layoutComponent.value), null, {
      default: _withCtx(() => [
        _createVNode(_component_router_view)
      ]),
      _: 1
    })),
    _createVNode(SmNotifyBar)
  ], 64))
}
}

})