import { RouteLocationNormalized } from 'vue-router';

/**
 * Этот middleware используется для динамического обновления системы Layouts.
 *
 * Как только маршрут изменяется, он пытается загрузить layout, который мы хотим отобразить, из meta маршрута. Затем он загружает компонент layout и присваивает загруженный компонент переменной layoutComponent в meta. layoutComponent используется в базовом layout-компоненте AppLayout.vue для динамического обновления.
 *
 * Если требуемый layout не найден, загружается стандартный layout DefaultLayout.vue.
 * */
export const loadLayout = async (route: RouteLocationNormalized): Promise<void> => {
  try {
    const layout = route.meta.layout as string;
    let layoutComponent;
    try {
      layoutComponent = await import(`~/layouts/${layout}.vue`);
    } catch (_) {
      layoutComponent = await import(`@core/layouts/${layout}.vue`);
    }
    route.meta.layoutComponent = layoutComponent.default;
  } catch (e) {
    let layoutComponent = await import(`~/layouts/DefaultLayout.vue`)
    route.meta.layoutComponent = layoutComponent.default
  }
};
