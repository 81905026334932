import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, vShow as _vShow, createVNode as _createVNode, withDirectives as _withDirectives, renderSlot as _renderSlot, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, withKeys as _withKeys, normalizeClass as _normalizeClass, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = ["type", "disabled", "tab-index", "aria-label"]
const _hoisted_2 = { class: "sm-button__content" }

import { computed, toRefs, ref } from 'vue';

// Composables
import { useButton } from '@/composables/useButton';

// Components
import SmIcon from '@/components/common/SmIcon/SmIcon.vue';
import SmLoader from '@/components/common/loaders/SmLoader.vue';

// Types
import { Class } from '@/types/common';
import { IButtonProps, ICommonEmits } from '@/types/components/buttons';

// Props


export default /*@__PURE__*/_defineComponent({
  __name: 'SmButton',
  props: {
    type: { default: 'primary' },
    loading: { type: Boolean, default: false },
    icon: { default: null },
    disabled: { type: Boolean, default: false },
    nativeType: { default: 'button' },
    tabIndex: { default: 0 },
    ariaLabel: { default: '' }
  },
  emits: ["click", "keydown.enter", "keyup.space"],
  setup(__props: any, { expose: __expose, emit: __emit }) {

// Modules
const props = __props;

const {
  type,
  loading,
  icon,
  disabled,
  nativeType,
  tabIndex,
  ariaLabel
} = toRefs(props);

// Emits

const emits = __emit;

// Composables

const {
  computedAriaLabel,
  handleClick,
  handleKeyDownEnter,
  handleKeyUpSpace
} = useButton({
  ariaLabel
}, emits);

// Reactive State
const buttonElement = ref<HTMLElement | null>(null);

// Computed
const classList = computed((): Class => {
  let classList: Class = [];

  classList.push(`sm-button--${type.value}`);

  classList.push({
    'sm-button--loading': loading.value
  });

  return classList;
});

// const contentClasslist = computed((): Class => ({
//   sm-bu
// }))

const computedDisabled = computed((): boolean => loading.value || disabled.value);
const showIcon = computed((): boolean => type.value !== 'text' && !!icon.value);

// Expose
__expose({
  buttonElement,
});

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("button", {
    type: _unref(nativeType),
    class: _normalizeClass(["sm-button", classList.value]),
    disabled: computedDisabled.value,
    role: "button",
    "tab-index": _unref(tabIndex),
    "aria-label": _unref(computedAriaLabel),
    ref_key: "buttonElement",
    ref: buttonElement,
    onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_unref(handleClick) && _unref(handleClick)(...args))),
    onKeydown: _cache[1] || (_cache[1] = _withKeys(
//@ts-ignore
(...args) => (_unref(handleKeyDownEnter) && _unref(handleKeyDownEnter)(...args)), ["enter"])),
    onKeyup: _cache[2] || (_cache[2] = _withKeys(
//@ts-ignore
(...args) => (_unref(handleKeyUpSpace) && _unref(handleKeyUpSpace)(...args)), ["space"]))
  }, [
    _withDirectives(_createVNode(SmLoader, { class: "sm-button__loading" }, null, 512), [
      [_vShow, _unref(loading)]
    ]),
    _createElementVNode("span", _hoisted_2, [
      _renderSlot(_ctx.$slots, "default"),
      (showIcon.value)
        ? (_openBlock(), _createBlock(SmIcon, {
            key: 0,
            class: "sm-button__icon",
            name: `${_unref(icon)}`
          }, null, 8, ["name"]))
        : _createCommentVNode("", true)
    ])
  ], 42, _hoisted_1))
}
}

})