import { NavigationGuardNext } from 'vue-router';
import { PermissionService } from '@core/services/permission';
import { usePermissionsStore } from '~/stores/permissions';
import { findRequiredPermission } from '~/helpers/findRequiredPermission';

export const checkPermissions = (to: any, next: NavigationGuardNext) => {
  const { permissions } = usePermissionsStore();
  const permissionService = new PermissionService(permissions);
  const requiredPermission = findRequiredPermission(to.name);
  
  if (requiredPermission) {
    const { controller, method, action } = requiredPermission;
    if (!permissionService.hasAccess(controller, method, action)) {
      return next({ name: 'HasNoAccess' });
    }
  }

  next();
};
