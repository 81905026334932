import { RouteRecordRaw } from '@vue-router';
import { configureRoutesMeta } from '@core/utils/configureRoutesMeta';
import { routes as baseRoutes } from './base';
import { routes as ownersOffices } from './ownersOffices';
import { routes as gis } from './gis';
import { routes as debtorRelations } from './debtorRelations';
import { routes as electronicControlRoom } from './electronicControlRoom';
import { routes as interactiveAccess } from './interactiveAccess';
import { routes as mailingDispatcher } from './mailingDispatcher';
import { routes as siteCompany } from './siteCompany';
import { routes as oneC } from './oneC';
import { routes as chatBot } from './chatBot';
import { routes as meetings } from './meetings';
// import DefaultLayout from '~/layouts/DefaultLayout.vue';

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    name: 'Home',
    component: () => import('~/views/Home.vue'),
  },
  {
    path: '/no-access',
    name: 'HasNoAccess',
    component: () => import('~/views/HasNoAccess.vue'),
  },
  ...baseRoutes,
  ...ownersOffices,
  ...interactiveAccess,
  ...gis,
  ...debtorRelations,
  ...electronicControlRoom,
  ...mailingDispatcher,
  ...siteCompany,
  ...oneC,
  ...chatBot,
  ...meetings,
  // TODO: Очень хотелось бы иметь такую структуру router, а не старую
  // {
  //   path: '/reports',
  //   name: 'Reports',
  //   component: DefaultLayout,
  //   children: [
  //     {
  //       path: 'test',
  //       name: 'Reports.Test',
  //       component: () => import('~/views/reports/TestReportView.vue'),
  //     }
  //   ]
  // }
  {
    path: '/reports/test',
    name: 'Reports.Test',
    component: () => import('~/views/reports/TestReportView.vue'),
    meta: {
      title: 'Тестовый отчет'
    }
  }
];

export const authorizedRoutes = configureRoutesMeta(routes, false, 'DefaultLayout');