const ownersOffices = {
  header: 'Кабинеты собственников',
  items: [
    {
      title: 'Настройки',
      list: [
        // {
        //   name: 'Настройки ЛК и мобильного приложения',
        //   route: { name: 'MobileSettings' },
        //   controllerName: 'Mobile',
        // },
        {
          name: 'Настройки автоснятия показаний ИПУ (запрета приема показаний)',
          route: { name: 'MeterAutoGettingValues' },
          permission: {
            controller: 'MeterAutoGettingValues',
            method: 'List',
            action: 'View',
          }
        },
        {
          name: 'Дома с запретом создания заявок',
          route: { name: 'SupportDeniedHouses' },
          permission: {
            controller: 'SupportDeniedHouses',
            method: 'List',
            action: 'View',
          }
        },
        {
          name: 'Настройки запрета приема показаний по объему',
          route: { name: 'MeterValuesDifferencesLimitsSettings' },
          permission: {
            controller: 'MeterValuesDifferencesLimitsSettings',
            method: 'List',
            action: 'View',
          }
        },
        {
          name: 'Задачи по отправке отчетов',
          route: { name: 'TasksToSendReports' },
          permission: {
            controller: 'TasksToSendReports',
            method: 'List',
            action: 'View',
          }
        },
        {
          name: 'Организации',
          route: { name: 'CompanyProfile' },
          permission: {
            controller: 'CompanyProfile',
            method: 'List',
            action: 'View',
          }
        },
        // {
        //   name: 'Настройки алиасов',
        //   route: { name: 'AliasSpecificSettings' },
        // },
        {
          name: 'Лимиты задержки передачи показаний',
          route: { name: 'MeterValuesSendingDelayLimits' },
          permission: {
            controller: 'MeterValuesSendingDelayLimits',
            method: 'List',
            action: 'View',
          }
        },
        // {
        //   name: 'Генерация pdf-квитанций',
        //   route: { name: 'CreateBillsPdfProcessor' },
        // },
      ],
    },
    {
      title: 'Списки собственников',
      list: [
        {
          name: 'Группы районов',
          route: { name: 'OwnersOfficesGroupsOfDistricts' },
          permission: {
            controller: 'GroupsOfDistricts',
            method: 'List',
            action: 'View',
          }
        },
        {
          name: 'Районы',
          route: { name: 'OwnersOfficesHomeGroups' },
          permission: {
            controller: 'HomeGroups',
            method: 'List',
            action: 'View',
          }
        },
        {
          name: 'Дома',
          route: { name: 'OwnersOfficesHouses' },
          permission: {
            controller: 'Houses',
            method: 'List',
            action: 'View',
          }
        },
        {
          name: 'Помещения',
          route: { name: 'OwnersOfficesPremises' },
          permission: {
            controller: 'Premises',
            method: 'List',
            action: 'View',
          }
        },
        {
          name: 'Лицевые счета',
          route: { name: 'OwnersOfficesAccounts' },
          permission: {
            controller: 'Accounts',
            method: 'List',
            action: 'View',
          }
        },
        {
          name: 'Мобильные пользователи',
          route: { name: 'OwnersOfficesMobileAccounts' },
          permission: {
            controller: 'MobileAccounts',
            method: 'List',
            action: 'View',
          }
        },
        {
          name: 'Подключаемые лицевые счета при регистрации',
          route: { name: 'OwnersOfficesMobileAutoAddAccounts' },
          permission: {
            controller: 'MobileAutoAddAccounts',
            method: 'List',
            action: 'View',
          }
        },
        {
          name: 'Устройства',
          route: { name: 'OwnersOfficesMobileAccountDevices' },
          permission: {
            controller: 'MobileAccountDevices',
            method: 'List',
            action: 'View',
          }
        },
        {
          name: 'Индивидуальные приборы учета',
          route: { name: 'Meters' },
          permission: {
            controller: 'Meters',
            method: 'List',
            action: 'View',
          }
        },
        {
          name: 'Показания индивидуальных ПУ',
          route: { name: 'MeterValues' },
          permission: {
            controller: 'MeterValues',
            method: 'List',
            action: 'View',
          }
        },
        {
          name: 'Поверки индивидуальных ПУ',
          route: { name: 'MeterVerifications' },
          permission: {
            controller: 'MeterVerifications',
            method: 'List',
            action: 'View',
          }
        },
        {
          name: 'Общедомовые приборы учета',
          route: { name: 'HouseMeters' },
          permission: {
            controller: 'HouseMeters',
            method: 'List',
            action: 'View',
          }
        },
        {
          name: 'Показания общедомовых ПУ',
          route: { name: 'HouseMeterValues' },
          permission: {
            controller: 'HouseMeterValues',
            method: 'List',
            action: 'View',
          }
        },
        {
          name: 'Поверки общедомовых ПУ',
          route: { name: 'HouseMeterVerifications' },
          permission: {
            controller: 'HouseMeterVerifications',
            method: 'List',
            action: 'View',
          }
        },
        {
          name: 'Заявки на поверку ИПУ',
          route: { name: 'MeterRecheckRequests' },
          permission: {
            controller: 'MeterRecheckRequests',
            method: 'List',
            action: 'View',
          }
        },
        {
          name: 'Квитанции',
          route: { name: 'Bills' },
          permission: {
            controller: 'Bills',
            method: 'List',
            action: 'View',
          }
        },
        {
          name: 'Квитанции по долгам',
          route: { name: 'DebtBills' },
          permission: {
            controller: 'DebtBills',
            method: 'List',
            action: 'View',
          }
        },
        {
          name: 'Результаты рассылки квитанций',
          route: { name: 'SentBills' },
          permission: {
            controller: 'SentBills',
            method: 'List',
            action: 'View',
          }
        },
        {
          name: 'Поставщики услуг',
          route: { name: 'ServiceSuppliers' },
          permission: {
            controller: 'ServiceSuppliers',
            method: 'List',
            action: 'View',
          }
        },
        {
          name: 'Поставщики услуг по домам',
          route: { name: 'HouseServiceSuppliers' },
          permission: {
            controller: 'HouseServiceSuppliers',
            method: 'List',
            action: 'View',
          }
        },
        {
          name: 'Черный список номеров телефона',
          route: { name: 'BlackListPhones' },
          permission: {
            controller: 'BlackListPhones',
            method: 'List',
            action: 'View',
          }
        },
      ],
    },
    {
      title: 'Обращения через ЛК',
      list: [
        {
          name: 'Уведомления',
          route: { name: 'Announcements' },
          permission: {
            controller: 'Announcements',
            method: 'List',
            action: 'View',
          }
        },
        // {
        //   name: 'Дерево решений по тех. поддержке',
        //   route: { name: 'TechnicalSupportHelpTree' },
        // },
      ],
    },
    {
      title: 'Интеграции с ИС',
      list: [
        // {
        //   name: 'Очередь заданий на загрузку',
        //   route: { name: 'RequestQueryReport' },
        // },
        {
          name: 'Сводный отчет по задачам ГИС',
          route: { name: 'GisExchangeTasksReport' },
          permission: {
            controller: 'GisExchangeTasksReport',
            method: 'List',
            action: 'View',
          }
        },
        // {
        //   name: 'Настройки обмена данными',
        //   route: { name: 'DataExchangeSettings' },
        //   controllerName: 'Site',
        // },
        // {
        //   name: 'Настройка правил обмена данными',
        //   route: { name: 'DataExchangeSettings' },
        // },
        {
          name: 'Правила обработки данных',
          route: { name: 'DataExchangeRules' },
          permission: {
            controller: 'DataExchangeRules',
            method: 'List',
            action: 'View',
          }
        },
        // {
        //   name: 'Поиск по очереди заданий',
        //   route: { name: 'RequestQueryReportProvider' },
        // },
        {
          name: 'Базы данных для синхронизации',
          route: { name: 'ExternalBase' },
          permission: {
            controller: 'ExternalBase',
            method: 'List',
            action: 'View',
          }
        },
        // {
        //   name: 'Правила формирования адреса дома',
        //   controllerName: 'Common',
        //   route: { name: 'CommonSettings' },
        // },
        {
          name: 'Форматы номеров лицевых счетов',
          route: { name: 'AccountIdentFormats' },
          permission: {
            controller: 'AccountIdentFormats',
            method: 'List',
            action: 'View',
          }
        },
      ],
    },
    {
      title: 'Монетизация',
      list: [
        {
          name: 'Группы доп. услуг',
          route: { name: 'AdditionalServiceGroups' },
          permission: {
            controller: 'AdditionalServiceGroups',
            method: 'List',
            action: 'View',
          }
        },
        {
          name: 'Доп. услуги',
          route: { name: 'AdditionalServices' },
          permission: {
            controller: 'AdditionalServices',
            method: 'List',
            action: 'View',
          }
        },
        {
          name: 'Магазины',
          route: { name: 'Shops' },
          permission: {
            controller: 'Shops',
            method: 'List',
            action: 'View',
          }
        },
        {
          name: 'Товары',
          route: { name: 'Goods' },
          permission: {
            controller: 'Goods',
            method: 'List',
            action: 'View',
          }
        },
        {
          name: 'Категории товаров',
          route: { name: 'GoodsCategories' },
          permission: {
            controller: 'GoodsCategories',
            method: 'List',
            action: 'View',
          }
        },
        {
          name: 'Прайс на доп услуги',
          route: { name: 'SupportAdditionalServices' },
          permission: {
            controller: 'SupportAdditionalServices',
            method: 'List',
            action: 'View',
          }
        },
        {
          name: 'Загрузка прайс листа',
          route: { name: 'ImportPriceList' },
          permission: {
            controller: 'ImportPriceList',
            method: 'Import',
            action: 'View',
          }
        },
        {
          name: 'Загрузка Товаров и Магазинов для Маркетплейса',
          route: { name: 'ImportGoodsAndShops' },
          permission: {
            controller: 'ImportGoodsAndShops',
            method: 'Import',
            action: 'View',
          }
        },
        {
          name: 'Загрузка доп.услуг для Маркетплейса',
          route: { name: 'ImportAdditionalServices' },
          permission: {
            controller: 'ImportAdditionalServices',
            method: 'Import',
            action: 'View',
          }
        },
        {
          name: 'Рекламные баннеры',
          route: { name: 'Banners' },
          permission: {
            controller: 'Banners',
            method: 'List',
            action: 'View',
          }
        },
      ]
    },
    {
      title: 'Платежи',
      list: [
        {
          name: 'Платежи',
          route: { name: 'PaymentsInfo' },
          permission: {
            controller: 'PaymentsInfo',
            method: 'List',
            action: 'View',
          }
        },
        {
          name: 'Реестр оплат',
          route: { name: 'RegisterPayment' },
          permission: {
            controller: 'PaymentRegistries',
            method: 'List',
            action: 'View',
          }
        },
        {
          name: 'Реестр оплат страховок',
          route: { name: 'InsurancePaymentRegistries' },
          permission: {
            controller: 'InsurancePaymentRegistries',
            method: 'List',
            action: 'View',
          }
        },
        {
          name: 'Мобильные платежи',
          route: { name: 'MobilePayments' },
          permission: {
            controller: 'MobilePayments',
            method: 'List',
            action: 'View',
          }
        },
        {
          name: 'Акт об оказании услуг',
          route: { name: 'ActOfServiceProvisionReport' },
          permission: {
            controller: 'Report',
            method: 'List',
            action: 'View',
          }
        },
      ],
    },
    {
      title: 'Отчеты',
      list: [
        {
          name: 'Информация по лицевому счету',
          route: { name: 'AccountInfoReport' },
          permission: {
            controller: 'Report',
            method: 'AccountInfoReport',
            action: 'View',
          }
        },
        {
          name: 'Информация по домам',
          route: { name: 'HousesInfoReport' },
          permission: {
            controller: 'Report',
            method: 'HousesInfoReport',
            action: 'View',
          }
        },
        {
          name: 'Выписка из лицевого счета',
          route: { name: 'AccountExtractReport' },
          permission: {
            controller: 'Report',
            method: 'AccountExtractReport',
            action: 'View',
          }
        },
        {
          name: 'Выписка по дому',
          route: { name: 'HouseExtractReport' },
          permission: {
            controller: 'Report',
            method: 'HouseExtractReport',
            action: 'View',
          }
        },
        {
          name: 'Выписка по организации',
          route: { name: 'CompanyExtractReport' },
          permission: {
            controller: 'Report',
            method: 'CompanyExtractReport',
            action: 'View',
          }
        },
        {
          name: 'Проверка начислений и платежей (Услуги)',
          route: { name: 'AccountingAndPaymentReport' },
          permission: {
            controller: 'Report',
            method: 'AccountingAndPaymentReport',
            action: 'View',
          }
        },
        {
          name: 'Проверка начислений и платежей (ЛС)',
          route: { name: 'AccountingAndPaymentByIdentReport' },
          permission: {
            controller: 'Report',
            method: 'AccountingAndPaymentByIdentReport',
            action: 'View',
          }
        },
        {
          name: 'Отчет о нарушении связанности данных',
          route: { name: 'CheckingReferenceIntegrityReport' },
          permission: {
            controller: 'Report',
            method: 'CheckingReferenceIntegrityReport',
            action: 'View',
          }
        },
        {
          name: 'Имеющиеся показания по приборам учета',
          route: { name: 'MeterExistValuesReport' },
          permission: {
            controller: 'Report',
            method: 'MeterExistValuesReport',
            action: 'View',
          }
        },
        {
          name: 'Проверка квитанций',
          route: { name: 'CheckingBillsReport' },
          permission: {
            controller: 'Report',
            method: 'CheckingBillsReport',
            action: 'View',
          }
        },
      ],
    },
    {
      title: 'Аналитические отчеты',
      list: [
        {
          name: 'Анализ использования мобильного приложения',
          route: { name: 'MobileUsersAnalisisReport' },
          permission: {
            controller: 'Report',
            method: 'MobileUsersAnalisisReport',
            action: 'View',
          }
        },
        {
          name: 'Показания приборов учета',
          route: { name: 'MeterValuesReport' },
          permission: {
            controller: 'Report',
            method: 'MeterValuesReport',
            action: 'View',
          }
        },
        {
          name: 'Показания ОДПУ, переданные через МП',
          route: { name: 'HouseMeterValuesReceivedReport' },
          permission: {
            controller: 'Report',
            method: 'HouseMeterValuesReceivedReport',
            action: 'View',
          }
        },
        {
          name: 'Отчет по QR-кодам приборов учета',
          route: { name: 'MetersQrCodesReport' },
          permission: {
            controller: 'Report',
            method: 'MetersQrCodesReport',
            action: 'View',
          }
        },
        {
          name: 'Статистика просмотров уведомлений',
          route: { name: 'MobileAnnouncementReport' },
          permission: {
            controller: 'Report',
            method: 'MobileAnnouncementReport',
            action: 'View',
          }
        },
        {
          name: 'Отчет по реестру оплат',
          route: { name: 'PaymentsRegistryReport' },
          permission: {
            controller: 'Report',
            method: 'PaymentsRegistryReport',
            action: 'View',
          }
        },
        {
          name: 'Динамика роста пользователей мобильного приложения',
          route: { name: 'MobileUsersInDynamicsReport' },
          permission: {
            controller: 'Report',
            method: 'MobileUsersInDynamicsReport',
            action: 'View',
          }
        },
        {
          name: 'Детальная статистика',
          route: { name: 'MobileDetailedStatisticsReport' },
          permission: {
            controller: 'Report',
            method: 'MobileDetailedStatisticsReport',
            action: 'View',
          }
        },
        {
          name: 'Сводная статистика',
          route: { name: 'MobileSummaryStatisticsReport' },
          permission: {
            controller: 'Report',
            method: 'MobileSummaryStatisticsReport',
            action: 'View',
          }
        },
        // {
        //   name: 'Журнал авторизации',
        //   route: { name: 'SessionsLog' },
        //   permission: {
        //     controller: 'Report',
        //     method: 'SessionsLog',
        //     action: 'View',
        //   }
        // },
        {
          name: 'Обращения с сайта',
          route: { name: 'CallbackRequestsReport' },
          permission: {
            controller: 'Report',
            method: 'CallbackRequestsReport',
            action: 'View',
          }
        },
        {
          name: 'Отчет по пользователям',
          route: { name: 'MobileAccountsInfoReport' },
          permission: {
            controller: 'Report',
            method: 'MobileAccountsInfoReport',
            action: 'View',
          }
        },
        {
          name: 'Отчет по лицевым счетам не привязанным к моб.аккаунтам',
          route: { name: 'IdentsWithoutMobileAccountReport' },
          permission: {
            controller: 'Report',
            method: 'IdentsWithoutMobileAccountReport',
            action: 'View',
          }
        },
        {
          name: 'Отчет по мобильным платежам',
          route: { name: 'MobilePaymentsReport' },
          permission: {
            controller: 'Report',
            method: 'MobilePaymentsReport',
            action: 'View',
          }
        },
        {
          name: 'Отчет по чекам',
          route: { name: 'PaymentsVsPaychecksReport' },
          permission: {
            controller: 'Report',
            method: 'PaymentsVsPaychecksReport',
            action: 'View',
          }
        },
        {
          name: 'Отчет по загруженным квитанциям',
          route: { name: 'LoadedReceiptsReport' },
          permission: {
            controller: 'Report',
            method: 'LoadedReceiptsReport',
            action: 'View',
          }
        },
        {
          name: 'Отчет по роботу по приему ППУ',
          route: { name: 'MeterValuesRobotReport' },
          permission: {
            controller: 'Report',
            method: 'MeterValuesRobotReport',
            action: 'View',
          }
        },
        {
          name: 'Отчет по роботу по приему показаний',
          route: { name: 'RobotForMeterValuesReport' },
          permission: {
            controller: 'Report',
            method: 'RobotForMeterValuesReport',
            action: 'View',
          }
        },
        {
          name: 'Отчет по роботу-диспетчеру',
          route: { name: 'RobotRequestsReport' },
          permission: {
            controller: 'Report',
            method: 'RobotRequestsReport',
            action: 'View',
          }
        },
        // {
        //   name: 'Переходы на внешние ресурсы',
        //   route: { name: 'TransitionsToExternalResources' },
        //   permission: {
        //     controller: 'Report',
        //     method: 'TransitionsToExternalResources',
        //     action: 'View',
        //   }
        // },
        {
          name: 'Отчет по подключенным лиц. счетам МОЕ',
          route: { name: 'MoeAccountsReport' },
          permission: {
            controller: 'Report',
            method: 'MoeAccountsReport',
            action: 'View',
          }
        },
      ],
    },
    {
      title: 'Бонусная система',
      list: [
        {
          name: 'Параметры начислений',
          route: { name: 'BonusSystem' },
          permission: {
            controller: 'BonusSystem',
            method: 'List',
            action: 'View',
          }
        },
        {
          name: 'Бонусные программы',
          route: { name: 'BonusPrograms' },
          permission: {
            controller: 'BonusProgram',
            method: 'List',
            action: 'View',
          }
        },
        {
          name: 'Отчет по бонусам',
          route: { name: 'BonusCashFlowReport' },
          permission: {
            controller: 'BonusCashFlowReport',
            method: 'List',
            action: 'View',
          }
        },
      ],
    },
    {
      title: 'Опросы',
      list: [
        {
          name: 'Опросы и вопросы',
          route: { name: 'QuestionGroups' },
          permission: {
            controller: 'QuestionGroups',
            method: 'List',
            action: 'View',
          }
        },
        {
          name: 'Отчет по опросам',
          route: { name: 'QuestionsReport' },
          permission: {
            controller: 'Report',
            method: 'QuestionsReport',
            action: 'View',
          }
        },
        {
          name: 'Результаты опросов',
          route: { name: 'QuestionResultsReport' },
          permission: {
            controller: 'Report',
            method: 'QuestionResultsReport',
            action: 'View',
          }
        },
      ],
    },
    {
      title: 'Загрузка из Excel и проверки',
      list: [
        {
          name: 'Загрузка дат ДРСО',
          route: { name: 'OwnersOfficesImportSupplyContractInfo' },
        },
        {
          name: 'Загрузка ФИАС домов',
          route: { name: 'OwnersOfficesImportFias' },
        },
        {
          name: 'Загрузка адресов домов по ФИАС коду',
          route: { name: 'OwnersOfficesImportFiasAddress' },
        },
        {
          name: 'Загрузка выполняемых работ',
          route: { name: 'OwnersOfficesImportExecutingJobs' },
        },
        {
          name: 'Загрузка приборов учета',
          route: { name: 'OwnersOfficesImportMeters' },
        },
        {
          name: 'Загрузка показаний приборов учета',
          route: { name: 'OwnersOfficesImportMeterValues' },
        },
        {
          name: 'Загрузка помещений',
          route: { name: 'OwnersOfficesImportPremises' },
        },
        {
          name: 'Проверка загрузки квитанций ',
          route: { name: 'OwnersOfficesBillsCheck' },
        },
        {
          name: 'Загрузка дел должников (расширенная)',
          route: { name: 'OwnersOfficesImportDebtCases' },
        },
        {
          name: 'Загрузка GUID ДРСО',
          route: { name: 'OwnersOfficesImportSupplyContractGuids' },
        },
        {
          name: 'Загрузка перечней и планов работ',
          route: { name: 'OwnersOfficesImportWorkLists' },
        },
        {
          name: 'Загрузка домов',
          route: { name: 'OwnersOfficesImportHouses' },
        },
        {
          name: 'Загрузка квитанций',
          route: { name: 'OwnersOfficesImportAccountDebtServices' },
        },
        {
          name: 'Загрузка квитанций расширенная',
          route: { name: 'OwnersOfficesImportBillsExtended' },
        },
        {
          name: 'Загрузка оплат',
          route: { name: 'OwnersOfficesImportPayments' },
        },
      ],
    },
    {
      title: 'Интернет-провайдеры',
      list: [
        {
          name: 'Интернет-провайдеры',
          route: { name: 'InternetProviders' },
          permission: {
            controller: 'InternetProviders',
            method: 'List',
            action: 'View',
          }
        },
        {
          name: 'Тарифы интернет-провайдеров',
          route: {
            name: 'InternetProvidersTariffs',
          },
          permission: {
            controller: 'InternetProvidersTariffs',
            method: 'List',
            action: 'View',
          }
        },
        {
          name: 'Подключенные тарифы интернет-провайдеров',
          route: {
            name: 'InternetProvidersTariffsConnected',
          },
          permission: {
            controller: 'InternetProvidersTariffs',
            method: 'List',
            action: 'View',
          }
        },
      ],
    },
    {
      title: 'Обработки',
      list: [
        {
          name: 'Запуск звонков через UIS с уведомлением о поверке прибора учета',
          route: { name: 'MeterRecheckCallerProcessor' },
          permission: {
            controller: 'Processor',
            method: 'MeterRecheckCallerProcessor',
            action: 'View',
          }
        },
        // ВЛОЖЕННЫЕ
        // {
        //   name: 'Загрузка данных о размере фондов капитального ремонта',
        //   route: { name: 'OwnersOffices.ImportCapitalFundSizeProcessor' },
        //   permission: {
        //     controller: 'Processor',
        //     method: 'ImportCapitalFundSizeProcessor',
        //     action: 'View',
        //   }
        // },
        // {
        //   name: 'Архивация приборов учета ИПУ списком из Excel',
        //   route: { name: 'ArchiveMetersByExcelProcessor' },
        //   permission: {
        //     controller: 'Processor',
        //     method: 'ArchiveMetersByExcelProcessor',
        //     action: 'View',
        //   }
        // },
        // {
        //   name: 'Архивация приборов учета ИПУ',
        //   route: { name: 'ArchiveMetersProcessor' },
        //   permission: {
        //     controller: 'Processor',
        //     method: 'ArchiveMetersProcessor',
        //     action: 'View',
        //   }
        // },
        // {
        //   name: 'Массовое закрытие ЛС списком из Excel',
        //   route: { name: 'CloseAccountsByExcelProcessor' },
        //   permission: {
        //     controller: 'Processor',
        //     method: 'CloseAccountsByExcelProcessor',
        //     action: 'View',
        //   }
        // },
        // {
        //   name: 'Массовое закрытие ЛС',
        //   route: { name: 'CloseAccountsProcessor' },
        //   permission: {
        //     controller: 'Processor',
        //     method: 'CloseAccountsProcessor',
        //     action: 'View',
        //   }
        // },
        // {
        //   name: 'Обновить ИЖКУ по результатам обработки шаблона ЛС ТКО',
        //   route: { name: 'OwnersOffices.AccountFromTkoTemplateProcessor' },
        //   permission: {
        //     controller: 'Processor',
        //     method: 'AccountFromTkoTemplateProcessor',
        //     action: 'View',
        //   }
        // },
        // {
        //   name: 'Выгрузить ЛС/ЕЛС в CSV',
        //   route: { name: 'OwnersOffices.AccountsCsvFileProcessor' },
        //   permission: {
        //     controller: 'Processor',
        //     method: 'AccountsCsvFileProcessor',
        //     action: 'View',
        //   }
        // },
        // {
        //   name: 'Выгрузить ЛС/ЕЛС в Excel',
        //   route: { name: 'OwnersOffices.AccountsExcelFileProcessor' },
        //   permission: {
        //     controller: 'Processor',
        //     method: 'AccountsExcelFileProcessor',
        //     action: 'View',
        //   }
        // },
        // {
        //   name: 'Удалить квитанции за период',
        //   route: { name: 'BillsDeleteByPeriodProcessor' },
        //   permission: {
        //     controller: 'Processor',
        //     method: 'BillsDeleteByPeriodProcessor',
        //     action: 'View',
        //   }
        // },
        // {
        //   name: 'Создать лицевые счета для кап.ремонта',
        //   route: { name: 'OwnersOffices.CreateCRAccountsProcessor' },
        //   permission: {
        //     controller: 'Processor',
        //     method: 'CreateCRAccountsProcessor',
        //     action: 'View',
        //   }
        // },
        // {
        //   name: 'Удаление показаний за период',
        //   route: { name: 'DeleteMeterValuesByPeriodProcessor' },
        //   permission: {
        //     controller: 'Processor',
        //     method: 'DeleteMeterValuesByPeriodProcessor',
        //     action: 'View',
        //   }
        // },
        // {
        //   name: 'Выгрузить доп. информацию по домам (XLSX)',
        //   route: { name: 'OwnersOffices.ExportHousesToExcelProcessor' },
        //   permission: {
        //     controller: 'Processor',
        //     method: 'ExportHousesToExcelProcessor',
        //     action: 'View'
        //   }
        // },
        // {
        //   name: 'Выгрузить дома без ФИАС в CSV файл',
        //   route: { name: 'OwnersOffices.ExportHousesWithoutFiasProcessor' },
        //   permission: {
        //     controller: 'Processor',
        //     method: 'ExportHousesWithoutFiasProcessor',
        //     action: 'View'
        //   }
        // },
        // {
        //   name: 'Экспорт номеров помещений ГИС в Excel',
        //   route: { name: 'OwnersOffices.ExportPremiseGisNumberProcessor' },
        //   permission: {
        //     controller: 'Processor',
        //     method: 'ExportPremiseGisNumberProcessor',
        //     action: 'View',
        //   }
        // },
        // {
        //   name: 'Заполнить пустые типы услуг в квитанциях',
        //   route: { name: 'FillEmptyTypesServicesForBillsProcessor' },
        //   permission: {
        //     controller: 'Processor',
        //     method: 'FillEmptyTypesServicesForBillsProcessor',
        //     action: 'View',
        //   }
        // },
        // {
        //   name: 'Выгрузить ЛС ТКО',
        //   route: { name: 'OwnersOffices.FillTemplateTKOAccountsProcessor' },
        //   permission: {
        //     controller: 'Processor',
        //     method: 'FillTemplateTKOAccountsProcessor',
        //     action: 'View'
        //   }
        // },
        // {
        //   name: 'Выгрузить квитанции ТКО',
        //   route: { name: 'FillTemplateWithTkoBillsProcessor' },
        //   permission: {
        //     controller: 'Processor',
        //     method: 'FillTemplateWithTkoBillsProcessor',
        //     action: 'View',
        //   }
        // },
        // {
        //   name: 'Мастер создания дома',
        //   route: { name: 'OwnersOffices.HouseCreationMasterProcessor' },
        //   permission: {
        //     controller: 'Processor',
        //     method: 'HouseCreationMasterProcessor',
        //     action: 'View',
        //   }
        // },
        // {
        //   name: 'Загрузить ФИАСы',
        //   route: { name: 'OwnersOffices.HouseFiasesProcessor' },
        //   permission: {
        //     controller: 'Processor',
        //     method: 'HouseFiasesProcessor',
        //     action: 'View',
        //   }
        // },
        // {
        //   name: 'Заполнить шаблон по добавлению ОЖФ в договор ТКО(XLSX)',
        //   route: { name: 'OwnersOffices.HousesForTkoContractProcessor' },
        //   permission: {
        //     controller: 'Processor',
        //     method: 'HousesForTkoContractProcessor',
        //     action: 'View',
        //   }
        // },
        // {
        //   name: 'Изменение типа домов по кол-ву помещений',
        //   route: { name: 'OwnersOffices.HouseTypeByPremiseTypeProcessor' },
        //   permission: {
        //     controller: 'Processor',
        //     method: 'HouseTypeByPremiseTypeProcessor',
        //     action: 'View',
        //   }
        // },
        // {
        //   name: 'Загрузить доп. информацию по домам (XLSX)',
        //   route: { name: 'OwnersOffices.ImportHousesFromExcelProcessor' },
        //   permission: {
        //     controller: 'Processor',
        //     method: 'ImportHousesFromExcelProcessor',
        //     action: 'View',
        //   }
        // },
        // {
        //   name: 'Слияние услуги/ресурса',
        //   route: { name: 'MergeServiceOnBillsProcessor' },
        //   permission: {
        //     controller: 'Processor',
        //     method: 'MergeServiceOnBillsProcessor',
        //     action: 'View',
        //   }
        // },
        // {
        //   name: 'Удалить помещения, не привязанные к ЛС',
        //   route: { name: 'OwnersOffices.PremisesNotLinkedToAccountRemoveProcessor' },
        //   permission: {
        //     controller: 'Processor',
        //     method: 'PremisesNotLinkedToAccountRemoveProcessor',
        //     action: 'View',
        //   }
        // },
        // {
        //   name: 'Перезаполнение типов услуг в квитанциях',
        //   route: { name: 'RefillServiceTypesInBillsProcessor' },
        //   permission: {
        //     controller: 'Processor',
        //     method: 'RefillServiceTypesInBillsProcessor',
        //     action: 'View',
        //   }
        // },
        // {
        //   name: 'Обновление адресов домов',
        //   route: { name: 'OwnersOffices.UpdateHousesAddressesProcessor' },
        //   permission: {
        //     controller: 'Processor',
        //     method: 'UpdateHousesAddressesProcessor',
        //     action: 'View',
        //   }
        // },
        // {
        //   name: 'Выгрузка оплат ТКО',
        //   route: { name: 'ExportTkoPaymentsInfoProcessor' },
        //   permission: {
        //     controller: 'Processor',
        //     method: 'ExportTkoPaymentsInfoProcessor',
        //     action: 'View',
        //   }
        // },
        // {
        //   name: 'Генерация pdf-квитанций',
        //   route: { name: 'CreateBillsPdfProcessor' },
        //   permission: {
        //     controller: 'Processor',
        //     method: 'CreateBillsPdfProcessor',
        //     action: 'View',
        //   }
        // },
      ]
    },
  ],
};

const gis = {
  header: 'ГИС ЖКХ',
  items: [
    {
      title: 'Обмены с ГИС ЖКХ',
      list: [
        {
          name: 'Обмены с ГИС ЖКХ',
          route: { name: 'GisAutoTaskPack' },
          permission: {
            controller: 'GisAutoTaskPack',
            method: 'List',
            action: 'View',
          }
        }
      ]
    },
    {
      title: 'Отчеты по ошибкам',
      list: [
        {
          name: 'Ошибки выгрузки ЛС в ГИС ЖКХ',
          route: { name: 'GISAccountExportErrorsReport' },
          permission: {
            controller: 'Report',
            method: 'GISAccountExportErrorsReport',
            action: 'View',
          }
        },
        {
          name: 'Ошибки выгрузки ПД в ГИС ЖКХ',
          route: { name: 'GISBillExportErrorsReport' },
          permission: {
            controller: 'Report',
            method: 'GISBillExportErrorsReport',
            action: 'View',
          }
        },
        {
          name: 'Ошибки выгрузки ИПУ в ГИС ЖКХ',
          route: { name: 'GISIndividualMeterExportErrorReport' },
          permission: {
            controller: 'Report',
            method: 'GISIndividualMeterExportErrorReport',
            action: 'View',
          }
        },
        {
          name: 'Ошибки выгрузки показаний ИПУ в ГИС ЖКХ',
          route: { name: 'GISIndividualMeterValueExportErrorReport' },
          permission: {
            controller: 'Report',
            method: 'GISIndividualMeterValueExportErrorReport',
            action: 'View',
          }
        },
        {
          name: 'Ошибки выгрузки ОДПУ в ГИС ЖКХ',
          route: { name: 'GISHouseMeterExportErrorReport' },
          permission: {
            controller: 'Report',
            method: 'GISHouseMeterExportErrorReport',
            action: 'View',
          }
        },
        {
          name: 'Ошибки выгрузки показаний ОДПУ в ГИС ЖКХ',
          route: { name: 'GISHouseMeterValueExportErrorReport' },
          permission: {
            controller: 'Report',
            method: 'GISHouseMeterValueExportErrorReport',
            action: 'View',
          }
        },
        {
          name: 'Ошибки выгрузки оплат в ГИС ЖКХ',
          route: { name: 'GISPaymentExportErrorReport' },
          permission: {
            controller: 'Report',
            method: 'GISPaymentExportErrorReport',
            action: 'View',
          }
        },
        {
          name: 'Ошибки выгрузки квитирования в ГИС ЖКХ',
          route: { name: 'GISAcknowledgmentExportErrorsReport' },
          permission: {
            controller: 'Report',
            method: 'GISAcknowledgmentExportErrorsReport',
            action: 'View',
          }
        },
      ],
    },

    {
      title: 'Обмен с ОСЗН',
      list: [
        {
          name: 'Отчет по заявкам',
          route: { name: 'DebtRequestStatisticsReport' },
          permission: {
            controller: 'Report',
            method: 'DebtRequestStatisticsReport',
            action: 'View',
          }
        },
        {
          name: 'Судебная задолженность',
          route: { name: 'PremiseDebtInfos' },
          permission: {
            controller: 'PremiseDebtInfos',
            method: 'List',
            action: 'View',
          }
        },
        {
          name: 'Отчет по должникам',
          route: { name: 'DebtorsReport' },
          permission: {
            controller: 'Report',
            method: 'DebtorsReport',
            action: 'View',
          }
        },
        {
          name: 'Отчет по запросам из ОСЗН по должникам',
          route: { name: 'DebtorsRequestsReport' },
          permission: {
            controller: 'Report',
            method: 'DebtorsRequestsReport',
            action: 'View',
          }
        },
        {
          name: 'Задачи по отправке отчетов',
          route: { name: 'TasksToSendReports' },
          permission: {
            controller: 'TasksToSendReports',
            method: 'List',
            action: 'View',
          }
        },
      ],
    },
    {
      title: 'Отчеты по статистике',
      list: [
        {
          name: 'Общая статистика выгрузки в ГИС ЖКХ',
          route: { name: 'GISUploadStatisticReport' },
          permission: {
            controller: 'Report',
            method: 'GISUploadStatisticReport',
            action: 'View',
          }
        },
        {
          name: 'Статистика выгрузки в ГИС ЖКХ по домам',
          route: { name: 'GISUploadStatisticByHouseReport' },
          permission: {
            controller: 'Report',
            method: 'GISUploadStatisticByHouseReport',
            action: 'View',
          }
        },
        {
          name: 'Паспорт выгрузки в ГИС ЖКХ',
          route: { name: 'GISExportCommonStatisticsReport' },
          permission: {
            controller: 'Report',
            method: 'GISExportCommonStatisticsReport',
            action: 'View',
          }
        },
        {
          name: 'Статистика по организациям',
          route: { name: 'GisUploadStatisticByCompanyReport' },
          permission: {
            controller: 'Report',
            method: 'GisUploadStatisticByCompanyReport',
            action: 'View',
          }
        },
        {
          name: 'Статистика по отозванным ПД',
          route: { name: 'WithdrawnBillsCountReport' },
          permission: {
            controller: 'Report',
            method: 'WithdrawnBillsCountReport',
            action: 'View',
          }
        },
      ],
    },
    {
      title: 'Отчеты НПДО',
      list: [
        {
          name: 'Отчет о выгрузке счет-квитанций',
          route: { name: 'GISContractBillsReport' },
          permission: {
            controller: 'Report',
            method: 'GISContractBillsReport',
            action: 'View',
          }
        },
        {
          name: 'Отчет о выгрузке по лицевым счетам',
          route: { name: 'GISContractIdentsReport' },
          permission: {
            controller: 'Report',
            method: 'GISContractIdentsReport',
            action: 'View',
          }
        },
        {
          name: 'Отчет о выгрузке по приборам учета',
          route: { name: 'GISContractMetersReport' },
          permission: {
            controller: 'Report',
            method: 'GISContractMetersReport',
            action: 'View',
          }
        },
        {
          name: 'Отчет о передаче показаний приборов учета',
          route: { name: 'GISContractMetersValuesReport' },
          permission: {
            controller: 'Report',
            method: 'GISContractMetersValuesReport',
            action: 'View',
          }
        },
        {
          name: 'Отчет об информации по запросам',
          route: { name: 'GISContractRequestsReport' },
          permission: {
            controller: 'Report',
            method: 'GISContractRequestsReport',
            action: 'View',
          }
        },
        {
          name: 'Отчет о выгрузке договоров',
          route: { name: 'GISContractsReport' },
          permission: {
            controller: 'Report',
            method: 'GISContractsReport',
            action: 'View',
          }
        },
      ],
    },
    {
      title: 'Загрузка из Excel и проверки',
      list: [
        {
          name: 'Загрузка дат ДРСО',
          route: { name: 'ImportSupplyContractInfo' },
        },
        {
          name: 'Загрузка ФИАС домов',
          route: { name: 'GisImportFias' },
        },
        {
          name: 'Загрузка адресов домов по ФИАС коду',
          route: { name: 'ImportFiasAddress' },
        },
        {
          name: 'Загрузка выполняемых работ',
          route: { name: 'ImportExecutingJobs' },
        },
        {
          name: 'Загрузка приборов учета',
          route: { name: 'ImportMeters' },
        },
        {
          name: 'Загрузка показаний приборов учета',
          route: { name: 'ImportMeterValues' },
        },
        {
          name: 'Загрузка помещений',
          route: { name: 'GisImportPremises' },
        },
        {
          name: 'Проверка загрузки квитанций ',
          route: { name: 'GisBillsCheck' },
        },
        {
          name: 'Загрузка дел должников (расширенная)',
          route: { name: 'GisImportDebtCases' },
        },
        {
          name: 'Загрузка GUID ДРСО',
          route: { name: 'GisImportSupplyContractGuids' },
        },
        {
          name: 'Загрузка перечней и планов работ',
          route: { name: 'GisImportWorkLists' },
        },
        {
          name: 'Загрузка домов',
          route: { name: 'GisImportHouses' },
        },
        {
          name: 'Загрузка квитанций',
          route: { name: 'GisImportAccountDebtServices' },
        },
        {
          name: 'Загрузка квитанций расширенная',
          route: { name: 'GisImportBillsExtended' },
        },
        {
          name: 'Загрузка оплат',
          route: { name: 'GisImportPayments' },
        },
      ],
    },
    {
      title: 'Новости для информирования граждан',
      list: [
        {
          name: 'Новости для информирования граждан',
          route: { name: 'GisNotifications' },
          permission: {
            controller: 'GisNotifications',
            method: 'List',
            action: 'View',
          }
        },
      ],
    },
    {
      title: 'Справочники',
      list: [
        {
          name: 'Договоры пользования ОИ',
          route: { name: 'PublicPropertyContracts' },
          permission: {
            controller: 'PublicPropertyContracts',
            method: 'List',
            action: 'View',
          },
        },
        {
          name: 'Объемы и качество КР и КУ',
          route: { name: 'CommunalSupplyInformation' },
          permission: {
            controller: 'CommunalSupplyInformation',
            method: 'List',
            action: 'View',
          },
        },
        {
          name: 'Информация о состоянии расчетов за КУ',
          route: { name: 'ResourceSettlement' },
          permission: {
            controller: 'ResourceSettlement',
            method: 'List',
            action: 'View',
          },
        },
        {
          name: 'Перерывы по коммунальным услугам',
          route: { name: 'ServiceInterruptions' },
          permission: {
            controller: 'ServiceInterruptions',
            method: 'List',
            action: 'View',
          },
        },
        {
          name: 'Плановые перерывы по коммунальным услугам',
          route: { name: 'OutageReport' },
          permission: {
            controller: 'OutageReport',
            method: 'List',
            action: 'View',
          },
        },
        {
          name: 'Страховые продукты',
          route: { name: 'InsuranceProducts' },
          permission: {
            controller: 'InsuranceProduct',
            method: 'List',
            action: 'View',
          },
        },
        {
          name: 'Сведения о размере платы',
          route: { name: 'ManagementContractPaymentsInfo' },
          permission: {
            controller: 'ManagementContractPaymentsInfo',
            method: 'List',
            action: 'View',
          },
        },
        {
          name: 'Информация о размере фондов КР',
          route: { name: 'CapitalRepairFundSizeInfo' },
          permission: {
            controller: 'CapitalRepairFundSizeInfo',
            method: 'List',
            action: 'View',
          },
        },
        {
          name: 'Тарифы на оплату коммунальных ресурсов',
          route: { name: 'TarifForPaymentOfCommunalResource' },
          permission: {
            controller: 'TarifForPaymentOfCommunalResource',
            method: 'List',
            action: 'View',
          },
        },
        {
          name: 'Типы коммунальных услуг',
          route: { name: 'CommunalResourceTypes' },
          permission: {
            controller: 'CommunalResourceTypes',
            method: 'List',
            action: 'View',
          },
        },
        {
          name: 'Типы жилищных услуг',
          route: { name: 'ServiceTypes' },
          permission: {
            controller: 'ServiceTypes',
            method: 'List',
            action: 'View',
          },
        },
        {
          name: 'Коммунальные ресурсы',
          route: { name: 'CommunalResources' },
          permission: {
            controller: 'CommunalResources',
            method: 'List',
            action: 'View',
          },
        },
        {
          name: 'Договоры ресурсоснабжения',
          route: { name: 'SupplyContract' },
          permission: {
            controller: 'SupplyContract',
            method: 'List',
            action: 'View',
          },
        },
        {
          name: 'Коммунальные услуги по ДУ',
          route: { name: 'CommunalServices' },
          permission: {
            controller: 'CommunalServices',
            method: 'List',
            action: 'View',
          },
        },
        {
          name: 'Лицензии',
          route: { name: 'Licenses' },
          permission: {
            controller: 'Licenses',
            method: 'List',
            action: 'View',
          },
        },
      ]
    },
    {
      title: 'Планы работ',
      list: [
        {
          name: 'Работы и услуги',
          route: { name: 'WorkServices' },
          permission: {
            controller: 'WorkServices',
            method: 'List',
            action: 'View',
          },        
        },
        {
          name: 'Перечни работ',
          route: { name: 'WorkList' },
          permission: {
            controller: 'WorkList',
            method: 'List',
            action: 'View',
          },        
        },
        {
          name: 'Планы работ',
          route: { name: 'WorkPlans' },
          permission: {
            controller: 'WorkPlans',
            method: 'List',
            action: 'View',
          },        
        },
        {
          name: 'Фиксация планов',
          route: { name: 'WorkPlanCompleted' },
          permission: {
            controller: 'WorkPlanCompleted',
            method: 'List',
            action: 'View',
          },
        },
      ],
    },
    {
      title: 'Платежные документы',
      list: [
        {
          name: 'Квитирование',
          route: { name: 'Acknowledgments' },
          permission: {
            controller: 'Acknowledgments',
            method: 'List',
            action: 'View',
          },
        },
      ],
    },
    // {
    //   title: 'Обработки', // ТОЛЬКО ВЛОЖЕННЫЕ
    //   list: [
    //     {
    //       name: 'Формирование файлов аудита',
    //       route: { name: 'AuditFilesProcessor' },
    //       permission: {
    //         controller: 'Processor',
    //         method: 'AuditFilesProcessor',
    //         action: 'View',
    //       }
    //     },
    //     {
    //       name: 'Загрузка данных о размере фондов капитального ремонта',
    //       route: { name: 'ImportCapitalFundSizeProcessor' },
    //       permission: {
    //         controller: 'Processor',
    //         method: 'ImportCapitalFundSizeProcessor',
    //         action: 'View',
    //       }
    //     },
    //     {
    //       name: 'Связи для квитирования',
    //       route: { name: 'AcknowledgementProcessor' },
    //       permission: {
    //         controller: 'Processor',
    //         method: 'AcknowledgementProcessor',
    //         action: 'View',
    //       }
    //     },
    //     {
    //       name: 'Записать Guidы договоров в ЛС (найти лс по ОЖФ)',
    //       route: { name: 'AccountContractsGuidByObjectsProcessor' },
    //       permission: {
    //         controller: 'Processor',
    //         method: 'AccountContractsGuidByObjectsProcessor',
    //         action: 'View',
    //       }
    //     },
    //     {
    //       name: 'Записать Guidы договоров в ЛС (найти лс по № договора)',
    //       route: { name: 'AccountContractsGuidUpdateProcessor' },
    //       permission: {
    //         controller: 'Processor',
    //         method: 'AccountContractsGuidUpdateProcessor',
    //         action: 'View',
    //       }
    //     },
    //     {
    //       name: 'Заполнение договоров тарифами',
    //       route: { name: 'AddTariffOnSupplyContractProcessor' },
    //       permission: {
    //         controller: 'Processor',
    //         method: 'AddTariffOnSupplyContractProcessor',
    //         action: 'View',
    //       }
    //     },
    //     {
    //       name: 'Заполнение информации по КУ и КР на основе ПД',
    //       route: { name: 'FillCommunalSupplyInformationProcessor' },
    //       permission: {
    //         controller: 'Processor',
    //         method: 'FillCommunalSupplyInformationProcessor',
    //         action: 'View',
    //       }
    //     },
    //     {
    //       name: 'Создание недостающего договора на основе ДРСО',
    //       route: { name: 'SupplyContractsCreateProcessor' },
    //       permission: {
    //         controller: 'Processor',
    //         method: 'SupplyContractsCreateProcessor',
    //         action: 'View',
    //       }
    //     },
    //     {
    //       name: 'Обновить ОЖФ в договорах',
    //       route: { name: 'SupplyContractsObjectsUpdateProcessor' },
    //       permission: {
    //         controller: 'Processor',
    //         method: 'SupplyContractsObjectsUpdateProcessor',
    //         action: 'View',
    //       }
    //     },
    //     {
    //       name: 'Расторгнуть ДРСО по закрытым л/сч',
    //       route: { name: 'SupplyContractsTerminateProcessor' },
    //       permission: {
    //         controller: 'Processor',
    //         method: 'SupplyContractsTerminateProcessor',
    //         action: 'View',
    //       }
    //     },
    //     {
    //       name: 'Обновить услуги на основе квитанций',
    //       route: { name: 'SupplyContractSubjectsProcessor' },
    //       permission: {
    //         controller: 'Processor',
    //         method: 'SupplyContractSubjectsProcessor',
    //         action: 'View',
    //       }
    //     },
    //     {
    //       name: 'Отчет по запросам сведений о должниках для ОСЗН',
    //       route: { name: 'DebtorsRequestsCustomReportProcessor' },
    //       permission: {
    //         controller: 'Processor',
    //         method: 'DebtorsRequestsCustomReportProcessor',
    //         action: 'View',
    //       }
    //     },
    //   ]
    // },
  ],
};

const debtors = {
  header: 'Работа с должниками',
  items: [
    {
      title: 'Справочники',
      list: [
        {
          name: 'Дома для работы с должниками',
          route: { name: 'HousesOfDebtors' },
          permission: {
            controller: 'HousesOfDebtors',
            method: 'List',
            action: 'View',
          }
        },
        {
          name: 'Шаблоны',
          route: { name: 'DebtorTemplates' },
          permission: {
            controller: 'DebtorTemplates',
            method: 'List',
            action: 'View',
          }
        },
        {
          name: 'Виды комментариев по досудебной работе',
          route: { name: 'DebtCasesPreTrialWorksTypes' },
          permission: {
            controller: 'DebtCasesPreTrialWorksTypes',
            method: 'List',
            action: 'View',
          }
        },
        {
          name: 'Судебные участки',
          route: { name: 'JudicialSectors' },
          permission: {
            controller: 'JudicialSectors',
            method: 'List',
            action: 'View',
          }
        },
        {
          name: 'Взыскатели',
          route: { name: 'Claimants' },
          permission: {
            controller: 'Claimants',
            method: 'List',
            action: 'View',
          }
        },
        {
          name: 'Типы взыскателей',
          route: { name: 'ClaimantsTypes' },
          permission: {
            controller: 'ClaimantsTypes',
            method: 'List',
            action: 'View',
          }
        },
        {
          name: 'Пользователи для работы с должниками',
          route: { name: 'UsersForDebtors' },
          permission: {
            controller: 'UsersForDebtors',
            method: 'List',
            action: 'View',
          }
        },
        {
          name: 'Этапы работы',
          route: { name: 'DebtProceedingStages' },
          permission: {
            controller: 'DebtProceedingStages',
            method: 'List',
            action: 'View',
          }
        },
      ]
    },
    {
      title: 'Формирование дел должников',
      list: [
        {
          name: 'Исполнители по делам должников',
          route: { name: 'AutoDebtCaseDispatchers' },
          permission: {
            controller: 'AutoDebtCaseDispatchers',
            method: 'List',
            action: 'View',
          }
        },
      ]
    },
    {
      title: 'Отчеты',
      list: [
        {
          name: 'Отчет должники по периодам',
          route: { name: 'DebtorsByPeriodsReport' },
          permission: { controller: 'Report', method: 'DebtorsByPeriodsReport', action: 'View' }
        },
        {
          name: 'Оплата по должникам',
          route: { name: 'DebtorsPaymentsReport' },
          permission: { controller: 'Report', method: 'DebtorsPaymentsReport', action: 'View' }
        },
        {
          name: 'Отчет по взыскателям (оплатам) за период',
          route: { name: 'ClaimantsReport' },
          permission: { controller: 'Report', method: 'ClaimantsReport', action: 'View' }
        },
        {
          name: 'База лицевых счетов (ведомость по должникам)',
          route: { name: 'DebtorsIdentsStatementReport' },
          permission: { controller: 'Report', method: 'DebtorsIdentsStatementReport', action: 'View' }
        },
        {
          name: 'Отчет по статусам работы с должниками',
          route: { name: 'DebtorsCasesStatusesReport' },
          permission: { controller: 'Report', method: 'DebtorsCasesStatusesReport', action: 'View' }
        },
        {
          name: 'Отчет по эффективности автообзвонов',
          route: { name: 'DebtorsInformingEffectivenessReport' },
          permission: { controller: 'Report', method: 'DebtorsInformingEffectivenessReport', action: 'View' }
        },
      ],
    },
    {
      title: 'Обработки',
      list: [
        {
          name: 'База лицевых счетов (ведомость по должникам)',
          route: { name: 'DebtorsIdentsStatementReportProcessor' },
          permission: { controller: 'Processor', method: 'DebtorsIdentsStatementReportProcessor', action: 'View' }
        },
        {
          name: 'Формирование дел должников',
          route: { name: 'CreateDebtCasesWithRequestProcessor' },
          permission: { controller: 'Processor', method: 'CreateDebtCasesWithRequestProcessor', action: 'View' }
        },
        {
          name: 'Загрузка дел должников из файла',
          route: { name: 'ImportDebtorsCases' },
          permission: { controller: 'Processor', method: 'ImportDebtorsCases', action: 'View' }
        },
      ]
    },
    {
      title: 'Общие справочники',
      list: [
        {
          name: 'Организации',
          route: { name: 'CompanyProfile' },
          permission: {
            controller: 'CompanyProfile',
            method: 'List',
            action: 'View',
          }
        },
        {
          name: 'Дома',
          route: { name: 'DebtorRelationsHouses' },
          permission: {
            controller: 'Houses',
            method: 'List',
            action: 'View',
          }
        },
        {
          name: 'Лицевые счета',
          route: { name: 'DebtorRelationsAccounts' },
          permission: {
            controller: 'Accounts',
            method: 'List',
            action: 'View',
          }
        },
        {
          name: 'Платежи',
          route: { name: 'PaymentsInfo' },
          permission: {
            controller: 'PaymentsInfo',
            method: 'List',
            action: 'View',
          }
        },
        {
          name: 'Квитанции',
          route: { name: 'Bills' },
          permission: {
            controller: 'Bills',
            method: 'List',
            action: 'View',
          }
        },
        {
          name: 'Пользователи',
          route: { name: 'AccessUsers' },
          permission: {
            controller: 'Users',
            method: 'List',
            action: 'View',
          }
        },
      ],
    },
  ],
};

const electronicControlRoom = {
  header: 'Электронная диспетчерская (Меню)',
  items: [
    {
      title: 'Заявки',
      list: [
        {
          name: 'Типы источника заявок',
          route: { name: 'RequestSourceTypes' },
          permission: {
            controller: 'RequestSourceTypes',
            method: 'List',
            action: 'View',
          }
        },
        {
          name: 'Типы заявок',
          route: { name: 'RequestTypes' },
          permission: {
            controller: 'RequestTypes',
            method: 'List',
            action: 'View',
          }
        },
        {
          name: 'Виды неисправностей',
          route: { name: 'MalfunctionTypes' },
          permission: {
            controller: 'MalfunctionTypes',
            method: 'List',
            action: 'View',
          }
        },
        {
          name: 'Подробные виды неисправностей',
          route: { name: 'DetailedMalfunctionTypes' },
          permission: {
            controller: 'DetailedMalfunctionTypes',
            method: 'List',
            action: 'View',
          }
        },
        {
          name: 'Подразделения',
          route: { name: 'Divisions' },
          permission: {
            controller: 'Divisions',
            method: 'List',
            action: 'View',
          }
        },
        {
          name: 'Пулы заявок',
          route: { name: 'RequestPools' },
          permission: {
            controller: 'RequestPools',
            method: 'List',
            action: 'View',
          }
        },
        {
          name: 'Email-источников заявок',
          route: { name: 'EmailGates' },
          permission: {
            controller: 'EmailGates',
            method: 'List',
            action: 'View',
          }
        },
        {
          name: 'Email правила фильтрации',
          route: { name: 'EmailGateFilters' },
          permission: {
            controller: 'EmailGateFilters',
            method: 'List',
            action: 'View',
          }
        },
        {
          name: 'Темы обращений к директору в Телеграм',
          route: { name: 'ToShareholderTelegramSubjects' },
          permission: {
            controller: 'ToShareholderTelegramSubjects',
            method: 'List',
            action: 'View',
          }
        },
        {
          name: 'Телеграм группы для отправки аварийных заявок',
          route: { name: 'EmergencyRequestChannels' },
          permission: {
            controller: 'EmergencyRequestChannels',
            method: 'List',
            action: 'View',
          }
        },
        {
          name: 'Информация в помощь диспетчеру',
          route: { name: 'HelpDispatcherGeneral' },
          permission: {
            controller: 'HelpDispatcherGeneral',
            method: 'List',
            action: 'View',
          }
        },
        {
          name: 'График работы сотрудников',
          route: { name: 'EmploymentCalendar' },
          permission: {
            controller: 'EmploymentCalendar',
            method: 'List',
            action: 'View',
          }
        },
        {
          name: 'Настройки для автоперевода заявок',
          route: { name: 'ElectronicControlRoomAutoMoveSettings' },
          permission: {
            controller: 'AutoMoveSettings',
            method: 'List',
            action: 'View',
          }
        },
        {
          name: 'Настройки автоподключения к просроченным заявкам',
          route: { name: 'ECRAutoSubscriptionForOverdue' },
          permission: {
            controller: 'AutoSubscriptionForOverdue',
            method: 'List',
            action: 'View',
          }
        },
        {
          name: 'Настройки автоперевода заявок с низкой оценкой',
          route: { name: 'ECRAutoMoveLowMark' },
          permission: {
            controller: 'AutoMoveLowMark',
            method: 'List',
            action: 'View',
          }
        },
        {
          name: 'Дополнительные поля заявок',
          route: { name: 'RequestAdditionalFields' },
          permission: {
            controller: 'RequestAdditionalFields',
            method: 'List',
            action: 'View',
          }
        },
        {
          name: 'Типы задач',
          route: { name: 'TaskTypes' },
          permission: {
            controller: 'TaskTypes',
            method: 'List',
            action: 'View',
          }
        },
      ],
    },
    {
      title: 'Звонки',
      list: [
        {
          name: 'Журнал звонков',
          route: { name: 'Calls' },
          permission: {
            controller: 'Calls',
            method: 'List',
            action: 'View',
          }
        },
        {
          name: 'Пропущенные звонки',
          route: { name: 'MissedCalls' },
          permission: {
            controller: 'MissedCalls',
            method: 'List',
            action: 'View',
          }
        },
        {
          name: 'Общий список пользователей телефонии',
          route: { name: 'VoipUsersCommonList' },
          permission: {
            controller: 'VoipUsersCommonList',
            method: 'List',
            action: 'View',
          }
        },
        {
          name: 'Параметры входящих номеров по событиям от АТС',
          route: { name: 'SupportAcceptorPhones' },
          permission: {
            controller: 'SupportAcceptorPhones',
            method: 'List',
            action: 'View',
          }
        },
      ],
    },
    {
      title: 'Адреса',
      list: [
        {
          name: 'Группы районов',
          route: { name: 'ElectronicControlRoomGroupsOfDistricts' },
          permission: {
            controller: 'GroupsOfDistricts',
            method: 'List',
            action: 'View',
          }
        },
        {
          name: 'Районы',
          route: { name: 'ElectronicControlRoomHomeGroups' },
          permission: {
            controller: 'HomeGroups',
            method: 'List',
            action: 'View',
          }
        },
        {
          name: 'Дома',
          route: { name: 'ElectronicControlRoomHouses' },
          permission: { controller: 'Houses', method: 'List', action: 'View' }
        },
        {
          name: 'Лицевые счета',
          route: { name: 'ElectronicControlRoomAccounts' },
          permission: {
            controller: 'Accounts',
            method: 'List',
            action: 'View',
          }
        },
    // {
    //   title: 'Заявки',
    //   list: [
    //     {
    //       name: 'Типы источника заявок',
    //       route: { name: 'RequestSourceTypes' },
    //       controllerName: 'RequestSourceTypes',
    //     },
    //     {
    //       name: 'Типы заявок',
    //       route: { name: 'RequestTypes' },
    //       controllerName: 'RequestTypes',
    //     },
    //     {
    //       name: 'Виды неисправностей',
    //       route: { name: 'MalfunctionTypes' },
    //       controllerName: 'MalfunctionTypes',
    //     },
    //     {
    //       name: 'Подробные виды неисправностей',
    //       route: { name: 'DetailedMalfunctionTypes' },
    //       controllerName: 'DetailedMalfunctionTypes',
    //     },
    //     {
    //       name: 'Подразделения',
    //       route: { name: 'Divisions' },
    //       controllerName: 'Divisions',
    //     },
    //     {
    //       name: 'Пулы заявок',
    //       route: { name: 'RequestPools' },
    //       controllerName: 'RequestPools',
    //     },
    //     {
    //       name: 'Email-источников заявок',
    //       route: { name: 'EmailGates' },
    //       controllerName: 'EmailGates',
    //     },
    //     {
    //       name: 'Email правила фильтрации',
    //       route: { name: 'EmailGateFilters' },
    //       controllerName: 'EmailGateFilters',
    //     },
    //     {
    //       name: 'Темы обращений к директору в Телеграм',
    //       route: { name: 'ToShareholderTelegramSubjects' },
    //       controllerName: 'ToShareholderTelegramSubjects',
    //     },
    //     {
    //       name: 'Аварийные работы',
    //       route: { name: 'EmergencyWorks' },
    //       controllerName: 'EmergencyWorks',
    //     },
    //     {
    //       name: 'Телеграм группы для отправки аварийных заявок',
    //       route: { name: 'EmergencyRequestChannels' },
    //       controllerName: 'EmergencyRequestChannels',
    //     },
    //     {
    //       name: 'Информация в помощь диспетчеру',
    //       route: { name: 'HelpDispatcherGeneral' },
    //       controllerName: 'HelpDispatcherGeneralInfo',
    //     },
    //     {
    //       name: 'График работы сотрудников',
    //       route: { name: 'EmploymentCalendar' },
    //       controllerName: 'EmploymentCalendar',
    //     },
    //     {
    //       name: 'Настройки для автоперевода заявок',
    //       route: { name: 'ElectronicControlRoomAutoMoveSettings' },
    //       routeName: 'AutoMoveSettings',
    //     },
    //     {
    //       name: 'Настройки автоподключения к просроченным заявкам',
    //       route: { name: 'ECRAutoSubscriptionForOverdue' },
    //       routeName: 'AutoSubscriptionForOverdue',
    //     },
    //     {
    //       name: 'Настройки автоперевода заявок с низкой оценкой',
    //       route: { name: 'ECRAutoMoveLowMark' },
    //       routeName: 'AutoMoveLowMark',
    //     },
    //     {
    //       name: 'Дополнительные поля заявок',
    //       route: { name: 'RequestAdditionalFields' },
    //       controllerName: 'RequestAdditionalFields',
    //     },
    //     {
    //       name: 'Типы задач',
    //       route: { name: 'TaskTypes' },
    //       controllerName: 'TaskTypes',
    //     },
    //   ],
    // },
    // {
    //   title: 'Звонки',
    //   list: [
    //     {
    //       name: 'Журнал звонков',
    //       route: { name: 'Calls' },
    //       controllerName: 'Calls',
    //     },
    //     {
    //       name: 'Пропущенные звонки',
    //       route: { name: 'MissedCalls' },
    //       controllerName: 'MissedCalls',
    //     },
    //     {
    //       name: 'Общий список пользователей телефонии',
    //       route: { name: 'VoipUsersCommonList' },
    //       controllerName: 'VoipUsersCommonList',
    //     },
    //     {
    //       name: 'Параметры входящих номеров по событиям от АТС',
    //       route: { name: 'SupportAcceptorPhones' },
    //       controllerName: 'SupportAcceptorPhones',
    //     },
    //   ],
    // },
    // {
    //   title: 'Адреса',
    //   list: [
    //     {
    //       name: 'Группы районов',
    //       route: { name: 'AddressesGroupsOfDistricts' },
    //       controllerName: 'GroupsOfDistricts',
    //     },
    //     {
    //       name: 'Районы',
    //       route: { name: 'AddressesHomeGroups' },
    //       controllerName: 'HomeGroups',
    //     },
    //     {
    //       name: 'Дома',
    //       route: { name: 'ElectronicControlRoomHouses' },
    //       controllerName: 'Houses',
    //     },
    //     {
    //       name: 'Лицевые счета',
    //       route: { name: 'ElectronicControlRoomAccounts' },
    //       controllerName: 'Accounts',
    //     },
      ]
    //     {
    //       name: 'Дома, обслуживаемые КЦ',
    //       route: { name: 'HousesServicedByCC' },
    //       controllerName: 'HousesServicedByCC',
    //     },
    //   ],
    },
    {
      title: 'Аналитические отчеты',
      list: [
        {
          name: 'Журнал заявок',
          route: { name: 'AdsJournalReport' },
        },
        {
          name: 'Журнал заявок (вариант 2)',
          route: { name: 'AdsJournalCapitalInvestReport' },
        },
        {
          name: 'Отчет по задачам',
          route: { name: 'TasksReport' },
        },
        {
          name: 'Анализ поступивших заявок',
          route: { name: 'AdsAnaliticalReport' },
        },
        {
          name: 'Жалобы и обращения',
          route: { name: 'AdsYammerRequestsReport' },
        },
        {
          name: 'Статистика звонков',
          route: { name: 'AdsCallsStatisticsReport' },
        },
        {
          name: 'Заявки по исполнителям',
          route: { name: 'AdsRequestsPerformingReport' },
        },
        {
          name: 'Мои выполненные заявки за период',
          route: { name: 'AdsMyPerformedJournalReport' },
        },
        {
          name: 'Оценка удовлетворенности',
          route: { name: 'AdsMarksOverviewReport' },
        },
        {
          name: 'Отчет по поступившим обращениям в диспетчерскую службу',
          route: { name: 'AdsIncomingRequestsReport' },
        },
        {
          name: 'Журнал оформленных заявок на пропуск',
          route: { name: 'AdsPassJournalReport' },
        },
        {
          name: 'Отчет по пропущенным звонкам',
          route: { name: 'AdsMissedCallReport' },
        },
        // TODO: Кастомный отчет
        // {
        //   name: 'Отчет по геолокации сотрудников',
        //   route: { name: 'AdsEmployeeGeolocation' },
        // },
        {
          name: 'Отчет по расходованию материалов',
          route: { name: 'MaterialsConsumptionReport' },
        },
        {
          name: 'Эксперт отчет по заявкам, подразделениям',
          route: { name: 'RequestDivisionReport' },
        },
        {
          name: 'Эксперт отчет по услугам',
          route: { name: 'SpecifiedServicesReport' },
        },
        {
          name: 'Эксперт отчет заявки по датам',
          route: { name: 'RequestDateReport' },
        },
        {
          name: 'Эксперт отчет по звонкам',
          route: { name: 'CallsReport' },
        },
        {
          name: 'Реестр телефонограмм',
          route: { name: 'PhonogramsReport' },
        },
        {
          name: 'Лицевые счета, получающие квитанции в эл. виде',
          route: { name: 'IdentsWithElectronicBillsReport' },
        },
        {
          name: 'Лицевые счета, изменившие галку согласия получать квитанции в электронном виде',
          route: { name: 'IdentsWithElectronicBillsChangesReport' },
        },
        {
          name: 'Отчет по дедлайну',
          route: { name: 'DeadlineReport' },
        },
        {
          name: 'Телефоны из заявок',
          route: { name: 'AdsRequestsPhonesReport' },
        },
      ],
    },
    {
      title: 'Объекты контроля',
      list: [
        {
          name: 'Объекты контроля',
          route: { name: 'ControlObjects' },
        },
      ],
    },
    {
      title: 'Настройки',
      list: [
    //     {
    //       name: 'Настройки диспетчерской службы',
    //       route: { name: 'SupportSettings' },
    //       controllerName: 'Support',
    //     },
        {
          name: 'Комментарии к телефонным номерам',
          route: { name: 'PhonesComments' },
          controllerName: 'PhonesComments',
        },
        {
          name: 'Ежедневное расписание работы',
          route: { name: 'DailyWorkSchedule' },
          controllerName: 'DailyWorkSchedule',
        },
      ],
    },
    {
      title: 'Управление пользователями',
      list: [
        {
          name: 'Пользователи',
          route: { name: 'Users' },
        },
        {
          name: 'Отчет по последней активности пользователей сотрудников',
          route: { name: 'LastUsersActivityReport' },
        },
        {
          name: 'Группы домов',
          route: { name: 'HouseGroups' },
        },
      ],
    },
    {
      title: 'Управление пропусками',
      list: [
        {
          name: 'Пропуска',
          route: { name: 'Passes' },
        },
        {
          name: 'Категории пропусков',
          route: { name: 'PassCategories' },
        },
        {
          name: 'Шаблоны номеров автотранспорта',
          route: { name: 'VehicleNumberTemplates' },
        },
        {
          name: 'Марки автомобилей',
          route: { name: 'VehicleMarks' },
        },
        {
          name: 'Черный список автотранспорта',
          route: { name: 'BlackListCarNumbers' },
        },
        {
          name: 'Черный список лицевых счетов',
          route: { name: 'BlackListIdents' },
        },
        {
          name: 'Загрузка марок автомобилей',
          route: { name: 'ImportVehicleMarks' },
        },
        {
          name: 'Загрузка черного списка автотранспорта',
          route: { name: 'ImportBlacklistCarNumbers' },
        },
        {
          name: 'Загрузка черного списка лицевых счетов',
          route: { name: 'ImportBlackListIdents' },
        },
      ],
    },
    {
      title: 'Документооборот',
      list: [
        {
          name: 'Типы документов',
          route: { name: 'DocumentTypes' },
        },
      ],
    },
    {
      title: 'Шаблоны',
      list: [
        {
          name: 'Шаблонные ответы диспетчера',
          route: { name: 'SupportPatternTexts' },
        },
        {
          name: 'Шаблоны уведомлений',
          route: { name: 'SupportNotificationTemplates' },
        },
        {
          name: 'Шаблоны документов',
          route: { name: 'AdsDocTemplates' },
        },
    //     {
    //       name: 'Как создать шаблон?',
    //       route: { name: 'AdsDocsHowCreateTemplate' },
    //       controllerName: 'AdsDocsHowCreateTemplate',
    //     },
      ],
    },
    {
      title: 'Телефонограммы',
      list: [
        {
          name: 'Типы телефонограмм',
          route: { name: 'PhonogramTypes' },
        },
      ],
    },
    {
      title: 'Работы и материалы',
      list: [
        {
          name: 'Типы материалов',
          route: { name: 'MaterialTypes' },
        },
        {
          name: 'Приход материалов',
          route: { name: 'MaterialJournal' },
        },
        // {
        //   name: 'Список работ',
        //   route: { name: 'ListOfWorks' },
        // },
      ],
    },
    {
      title: 'Интеграция с внешними системами',
      list: [
        {
          name: 'Периодические задачи обмена с ГИС ЖКХ и ЕДС МО',
          route: { name: 'GisAdsAutoTaskPack' },
          controllerName: 'GisAdsAutoTaskPack',
        },
      ],
    },
    //   ],
    // },
    // {
    //   title: 'Телефонограммы',
    //   list: [
    //     {
    //       name: 'Типы телефонограмм',
    //       route: { name: 'PhonogramTypes' },
    //       controllerName: 'PhonogramTypes',
    //     },
    //   ],
    // },
    // {
    //   title: 'Работы и материалы',
    //   list: [
    //     {
    //       name: 'Типы материалов',
    //       route: { name: 'MaterialTypes' },
    //       controllerName: 'MaterialTypes',
    //     },
    //     {
    //       name: 'Приход материалов',
    //       route: { name: 'MaterialJournal' },
    //       controllerName: 'MaterialJournal',
    //     },
    //     {
    //       name: 'Список работ',
    //       route: { name: 'ListOfWorks' },
    //       controllerName: 'ListOfWorks',
    //     },
    //   ],
    // },
    // {
    //   title: 'Интеграция с внешними системами',
    //   list: [
    //     {
    //       name: 'Периодические задачи обмена с ГИС ЖКХ и ЕДС МО',
    //       route: { name: 'GisAdsAutoTaskPack' },
    //       controllerName: 'GisAdsAutoTaskPack',
    //     },
    //   ],
    // },
    // {
    //   title: 'Обработки', // ТОЛЬКО ВЛОЖЕННЫЕ
    //   list: [
        // {
        //   name: 'Обновить ИЖКУ по результатам обработки шаблона ЛС ТКО',
        //   route: { name: 'AccountFromTkoTemplateProcessor' },
        //   permission: { controller: 'Processor', method: 'AccountFromTkoTemplateProcessor', action: 'View' }
        // },
        // {
        //   name: 'Выгрузить ЛС/ЕЛС в CSV',
        //   route: { name: 'AccountsCsvFileProcessor' },
        //   permission: { controller: 'Processor', method: 'AccountsCsvFileProcessor', action: 'View' }
        // },
        // {
        //   name: 'Выгрузить ЛС/ЕЛС в Excel',
        //   route: { name: 'AccountsExcelFileProcessor' },
        //   permission: { controller: 'Processor', method: 'AccountsExcelFileProcessor', action: 'View' }
        // },
        // {
        //   name: 'Создать лицевые счета для кап.ремонта',
        //   route: { name: 'CreateCRAccountsProcessor' },
        //   permission: { controller: 'Processor', method: 'CreateCRAccountsProcessor', action: 'View' }
        // },
        // {
        //   name: 'Выгрузить доп. информацию по домам (XLSX)',
        //   route: { name: 'ExportHousesToExcelProcessor' },
        //   permission: { controller: 'Processor', method: 'ExportHousesToExcelProcessor', action: 'View' }
        // },
        // {
        //   name: 'Выгрузить дома без ФИАС в CSV файл',
        //   route: { name: 'ExportHousesWithoutFiasProcessor' },
        //   permission: { controller: 'Processor', method: 'ExportHousesWithoutFiasProcessor', action: 'View' }
        // },
        // {
        //   name: 'Выгрузить ЛС ТКО',
        //   route: { name: 'FillTemplateTKOAccountsProcessor' },
        //   permission: { controller: 'Processor', method: 'FillTemplateTKOAccountsProcessor', action: 'View' }
        // },
        // {
        //   name: 'Мастер создания дома',
        //   route: { name: 'HouseCreationMasterProcessor' },
        //   permission: { controller: 'Processor', method: 'HouseCreationMasterProcessor', action: 'View' }
        // },
        // {
        //   name: 'Загрузить ФИАСы',
        //   route: { name: 'HouseFiasesProcessor' },
        //   permission: { controller: 'Processor', method: 'HouseFiasesProcessor', action: 'View' }
        // },
        // {
        //   name: 'Заполнить шаблон по добавлению ОЖФ в договор ТКО(XLSX)',
        //   route: { name: 'HousesForTkoContractProcessor' },
        //   permission: { controller: 'Processor', method: 'HousesForTkoContractProcessor', action: 'View' }
        // },
        // {
        //   name: 'Изменение типа домов по кол-ву помещений',
        //   route: { name: 'HouseTypeByPremiseTypeProcessor' },
        //   permission: { controller: 'Processor', method: 'HouseTypeByPremiseTypeProcessor', action: 'View' }
        // },
        // {
        //   name: 'Загрузить доп. информацию по домам (XLSX)',
        //   route: { name: 'ImportHousesFromExcelProcessor' },
        //   permission: { controller: 'Processor', method: 'ImportHousesFromExcelProcessor', action: 'View' }
        // },
        // {
        //   name: 'Удалить помещения, не привязанные к ЛС',
        //   route: { name: 'PremisesNotLinkedToAccountRemoveProcessor' },
        //   permission: { controller: 'Processor', method: 'PremisesNotLinkedToAccountRemoveProcessor', action: 'View' }
        // },
        // {
        //   name: 'Обновление адресов домов',
        //   route: { name: 'UpdateHousesAddressesProcessor' },
        //   permission: { controller: 'Processor', method: 'UpdateHousesAddressesProcessor', action: 'View' }
        // },
        // {
        //   name: 'Заполнение графика работы организации',
        //   route: { name: 'FillDailyWorkScheduleProcessor' },
        //   permission: { controller: 'Processor', method: 'FillDailyWorkScheduleProcessor', action: 'View' }
        // },
    //   ]
  ],
};

const interactiveAccess = {
  header: 'СКУД',
  items: [
    {
      title: 'Объекты СКУД',
      list: [
        {
          name: 'Объекты СКУД',
          route: { name: 'InteractiveAccessObjects' },
        },
        {
          name: 'Серверы СКУД',
          route: { name: 'InteractiveAccessObjectServers' },
        },
        {
          name: 'Типы объектов СКУД',
          route: { name: 'InteractiveAccessObjectTypes' },
        },
      ],
    },
    {
      title: 'Камеры',
      list: [
        {
          name: 'Камеры домов',
          route: { name: 'HouseCameras' },
        },
        {
          name: 'Группы камер и объектов доступа',
          route: { name: 'ObjectGroups' },
        },
      ],
    },
    {
      title: 'Пользователи',
      list: [
        {
          name: 'Пользователи объектов доступа',
          route: { name: 'PremisesIntercoms' },
        },
        {
          name: 'Загрузка пользователей объектов доступа',
          route: { name: 'ImportPremisesIntercoms' },
        },
      ]
    },
    {
      title: 'Журналы',
      list: [
        {
          name: 'Журнал активации объектов СКУД',
          route: { name: 'InteractiveAccessObjectsActivationsLog' },
        },
        {
          name: 'Журнал вызовов домофона',
          route: { name: 'InteractiveAccessObjectsPushLog' },
        },
        {
          name: 'Выданные права доступа',
          route: { name: 'DelegatedRights' },
        },
        {
          name: 'Информация о событиях пользователя',
          route: { name: 'UserLogInformation' },
        },
      ],
    },
    {
      title: 'Списки собственников',
      list: [
        {
          name: 'Группы районов',
          route: { name: 'InteractiveAccessGroupsOfDistricts' },
        },
        {
          name: 'Районы',
          route: { name: 'InteractiveAccessHomeGroups' },
        },
        {
          name: 'Дома',
          route: { name: 'InteractiveAccessHouses' },
        },
        {
          name: 'Помещения',
          route: { name: 'InteractiveAccessPremises' },
        },
        {
          name: 'Лицевые счета',
          route: { name: 'InteractiveAccessAccounts' },
        },
        {
          name: 'Мобильные пользователи',
          route: { name: 'InteractiveAccessMobileAccounts' },
        },
        {
          name: 'Устройства',
          route: { name: 'InteractiveAccessMobileAccountDevices' },
        },
      ],
    },
  ]
    // {
    //   title: 'Обработки', // ТОЛЬКО ВЛОЖЕННЫЕ
    //   list: [
    //     {
    //       name: 'Записать данные АТС в помещения',
    //       route: { name: 'AtsFillPremisesByHouseProcessor' },
    //       permission: {
    //         controller: 'Processor',
    //         method: 'AtsFillPremisesByHouseProcessor',
    //         action: 'View',
    //       }
    //     },
    //     {
    //       name: 'Экспорт номеров помещений ГИС в Excel',
    //       route: { name: 'ExportPremiseGisNumberProcessor' },
    //       permission: {
    //         controller: 'Processor',
    //         method: 'ExportPremiseGisNumberProcessor',
    //         action: 'View',
    //       }
    //     },
    //     {
    //       name: 'Обновить ИЖКУ по результатам обработки шаблона ЛС ТКО',
    //       route: { name: 'InteractiveAccess.AccountFromTkoTemplateProcessor' },
    //       permission: {
    //         controller: 'Processor',
    //         method: 'AccountFromTkoTemplateProcessor',
    //         action: 'View',
    //       }
    //     },
    //     {
    //       name: 'Выгрузить ЛС/ЕЛС в CSV',
    //       route: { name: 'InteractiveAccess.AccountsCsvFileProcessor' },
    //       permission: { 
    //         controller: 'Processor',
    //         method: 'AccountsCsvFileProcessor',
    //         action: 'View',
    //       }
    //     },
    //     {
    //       name: 'Выгрузить ЛС/ЕЛС в Excel',
    //       route: { name: 'InteractiveAccess.AccountsExcelFileProcessor' },
    //       permission: { 
    //         controller: 'Processor',
    //         method: 'AccountsExcelFileProcessor',
    //         action: 'View',
    //       }
    //     },
    //     {
    //       name: 'Создать лицевые счета для кап.ремонта',
    //       route: { name: 'InteractiveAccess.CreateCRAccountsProcessor' },
    //       permission: { 
    //         controller: 'Processor',
    //         method: 'CreateCRAccountsProcessor',
    //         action: 'View',
    //       }
    //     },
    //     {
    //       name: 'Выгрузить доп. информацию по домам (XLSX)',
    //       route: { name: 'InteractiveAccess.ExportHousesToExcelProcessor' },
    //       permission: {
    //         controller: 'Processor',
    //         method: 'ExportHousesToExcelProcessor',
    //         action: 'View'
    //       }
    //     },
    //     {
    //       name: 'Выгрузить дома без ФИАС в CSV файл',
    //       route: { name: 'InteractiveAccess.ExportHousesWithoutFiasProcessor' },
    //       permission: {
    //         controller: 'Processor',
    //         method: 'ExportHousesWithoutFiasProcessor',
    //         action: 'View'
    //       }
    //     },
    //     {
    //       name: 'Выгрузить ЛС ТКО',
    //       route: { name: 'InteractiveAccess.FillTemplateTKOAccountsProcessor' },
    //       permission: {
    //         controller: 'Processor',
    //         method: 'FillTemplateTKOAccountsProcessor',
    //         action: 'View'
    //       }
    //     },
    //     {
    //       name: 'Мастер создания дома',
    //       route: { name: 'InteractiveAccess.HouseCreationMasterProcessor' },
    //       permission: {
    //         controller: 'Processor',
    //         method: 'HouseCreationMasterProcessor',
    //         action: 'View',
    //       }
    //     },
    //     {
    //       name: 'Загрузить ФИАСы',
    //       route: { name: 'InteractiveAccess.HouseFiasesProcessor' },
    //       permission: {
    //         controller: 'Processor',
    //         method: 'HouseFiasesProcessor',
    //         action: 'View',
    //       }
    //     },
    //     {
    //       name: 'Заполнить шаблон по добавлению ОЖФ в договор ТКО(XLSX)',
    //       route: { name: 'InteractiveAccess.HousesForTkoContractProcessor' },
    //       permission: {
    //         controller: 'Processor',
    //         method: 'HousesForTkoContractProcessor',
    //         action: 'View',
    //       }
    //     },
    //     {
    //       name: 'Изменение типа домов по кол-ву помещений',
    //       route: { name: 'InteractiveAccess.HouseTypeByPremiseTypeProcessor' },
    //       permission: {
    //         controller: 'Processor',
    //         method: 'HouseTypeByPremiseTypeProcessor',
    //         action: 'View',
    //       }
    //     },
    //     {
    //       name: 'Загрузить доп. информацию по домам (XLSX)',
    //       route: { name: 'InteractiveAccess.ImportHousesFromExcelProcessor' },
    //       permission: {
    //         controller: 'Processor',
    //         method: 'ImportHousesFromExcelProcessor',
    //         action: 'View',
    //       }
    //     },
    //     {
    //       name: 'Удалить помещения, не привязанные к ЛС',
    //       route: { name: 'InteractiveAccess.PremisesNotLinkedToAccountRemoveProcessor' },
    //       permission: {
    //         controller: 'Processor',
    //         method: 'PremisesNotLinkedToAccountRemoveProcessor',
    //         action: 'View',
    //       }
    //     },
    //     {
    //       name: 'Обновление адресов домов',
    //       route: { name: 'InteractiveAccess.UpdateHousesAddressesProcessor' },
    //       permission: {
    //         controller: 'Processor',
    //         method: 'UpdateHousesAddressesProcessor',
    //         action: 'View',
    //       }
    //     },
    //   ]
    // },
};

const siteCompany = {
  header: 'Сайт компании ЖКХ',
  items: [
    {
      title: 'Параметры сайта',
      list: [
        {
          name: 'Дополнительные параметры',
          route: { name: 'AdditionalOptions' },
          permission: {
            controller: 'AdditionalOptions',
            method: 'List',
            action: 'View',
          }
        }
      ]
    },
    {
      title: 'Наполнение сайта',
      list: [
        {
          name: 'Новости',
          route: { name: 'News' },
          permission: {
            controller: 'News',
            method: 'List',
            action: 'View',
          }
        },
        {
          name: 'Документы',
          route: { name: 'Documents' },
          permission: {
            controller: 'Documents',
            method: 'List',
            action: 'View',
          }
        },
        {
          name: 'Группы Документов',
          route: { name: 'GroupDocuments' },
          permission: {
            controller: 'GroupDocuments',
            method: 'List',
            action: 'View',
          }
        },
        {
          name: 'Статьи',
          route: { name: 'Articles' },
          permission: {
            controller: 'Articles',
            method: 'List',
            action: 'View',
          }
        },
        {
          name: 'Фотоотчеты',
          route: { name: 'PhotoReports' },
          permission: {
            controller: 'PhotoReports',
            method: 'List',
            action: 'View',
          }
        },
        {
          name: 'Файлы',
          route: { name: 'Files' },
          permission: {
            controller: 'Files',
            method: 'List',
            action: 'View',
          }
        },
        {
          name: 'Дома',
          route: { name: 'SiteCompany.Houses' },
          permission: {
            controller: 'Houses',
            method: 'List',
            action: 'View',
          }
        },
      ]
    },
    {
      title: 'Галерея',
      list: [
        {
          name: 'Альбомы',
          route: { name: 'GalleryAlbums' },
          permission: {
            controller: 'GalleryAlbums',
            method: 'List',
            action: 'View',
          }
        },
        {
          name: 'Изображения',
          route: { name: 'GalleryImages' },
          permission: {
            controller: 'GalleryImages',
            method: 'List',
            action: 'View',
          }
        },
        {
          name: 'Видео',
          route: { name: 'GalleryVideos' },
          permission: {
            controller: 'GalleryVideos',
            method: 'List',
            action: 'View',
          }
        },
      ]
    },
    {
      title: 'Меню (новый сайт)',
      list: [
        {
          name: 'Меню',
          route: { name: 'SiteMenuItems' },
          permission: {
            controller: 'SiteMenuItems',
            method: 'List',
            action: 'View',
          }
        }
      ]
    },
  ]
}

const oneC = {
  header: '1С',
  items: [
    {
      title: 'Справочники',
      list: [
        {
          name: 'Архив информационных баз 1С',
          route: { name: 'Archives' },
          permission: {
            controller: 'Archives',
            method: 'List',
            action: 'View',
          }
        },
        {
          name: 'Профили пользователей',
          route: { name: 'Profiles' },
          permission: {
            controller: 'Profiles',
            method: 'List',
            action: 'View',
          }
        },
      ]
    }
    // {
    //   title: 'Обработки', // ТОЛЬКО ВЛОЖЕННЫЕ
    //   list: [
    //     {
    //       name: 'Выгрузить доп. информацию по домам (XLSX)',
    //       route: { name: 'SiteCompany.ExportHousesToExcelProcessor' },
    //       permission: {
    //         controller: 'Processor',
    //         method: 'ExportHousesToExcelProcessor',
    //         action: 'View'
    //       }
    //     },
    //     {
    //       name: 'Выгрузить дома без ФИАС в CSV файл',
    //       route: { name: 'SiteCompany.ExportHousesWithoutFiasProcessor' },
    //       permission: {
    //         controller: 'Processor',
    //         method: 'ExportHousesWithoutFiasProcessor',
    //         action: 'View'
    //       }
    //     },
    //     {
    //       name: 'Мастер создания дома',
    //       route: { name: 'SiteCompany.HouseCreationMasterProcessor' },
    //       permission: {
    //         controller: 'Processor',
    //         method: 'HouseCreationMasterProcessor',
    //         action: 'View',
    //       }
    //     },
    //     {
    //       name: 'Загрузить ФИАСы',
    //       route: { name: 'SiteCompany.HouseFiasesProcessor' },
    //       permission: {
    //         controller: 'Processor',
    //         method: 'HouseFiasesProcessor',
    //         action: 'View',
    //       }
    //     },
    //     {
    //       name: 'Заполнить шаблон по добавлению ОЖФ в договор ТКО(XLSX)',
    //       route: { name: 'SiteCompany.HousesForTkoContractProcessor' },
    //       permission: {
    //         controller: 'Processor',
    //         method: 'HousesForTkoContractProcessor',
    //         action: 'View',
    //       }
    //     },
    //     {
    //       name: 'Изменение типа домов по кол-ву помещений',
    //       route: { name: 'SiteCompany.HouseTypeByPremiseTypeProcessor' },
    //       permission: {
    //         controller: 'Processor',
    //         method: 'HouseTypeByPremiseTypeProcessor',
    //         action: 'View',
    //       }
    //     },
    //     {
    //       name: 'Загрузить доп. информацию по домам (XLSX)',
    //       route: { name: 'SiteCompany.ImportHousesFromExcelProcessor' },
    //       permission: {
    //         controller: 'Processor',
    //         method: 'ImportHousesFromExcelProcessor',
    //         action: 'View',
    //       }
    //     },
    //     {
    //       name: 'Удалить помещения, не привязанные к ЛС',
    //       route: { name: 'SiteCompany.PremisesNotLinkedToAccountRemoveProcessor' },
    //       permission: {
    //         controller: 'Processor',
    //         method: 'PremisesNotLinkedToAccountRemoveProcessor',
    //         action: 'View',
    //       }
    //     },
    //     {
    //       name: 'Обновление адресов домов',
    //       route: { name: 'SiteCompany.UpdateHousesAddressesProcessor' },
    //       permission: {
    //         controller: 'Processor',
    //         method: 'UpdateHousesAddressesProcessor',
    //         action: 'View',
    //       }
    //     },
    //   ]
    // },
  ],
}

export default {
  debtors,
  electronicControlRoom,
  ownersOffices,
  gis,
  interactiveAccess,
  siteCompany,
  oneC,
}