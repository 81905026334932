import { RouteRecordRaw } from 'vue-router';

export const routes: Array<RouteRecordRaw> = [
  // {START} lists
  {
    path: '/chat-bot-intent',
    name: 'ChatBotIntent',
    component: () => import('~/views/chatBot/lists/ChatBotIntent.vue'),
    meta: {
      title: 'Чат-бот Темы',
    }
  }, 
  // {END} lists
];