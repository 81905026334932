import { apiMngClient } from "~/plugins/http";
import { getCurrentBase } from "~/helpers/persistenceBase";
import { IPermissions } from "@core/types/permissions";

export const getCurrentUserPermissions = async (): Promise<IPermissions> => {
  try {
    const { data } = await apiMngClient.get(`${getCurrentBase()}/CurrentUser/Permissions`);
    
    return data; 
  } catch (error) {
    throw error; 
  }
};
