import { RouteRecordRaw } from 'vue-router';

export const routes: Array<RouteRecordRaw> = [
  {
    path: '/one-c/',
    name: 'OneC',
    component: () => import('~/views/oneC/OneC.vue'),
    meta: {
      title: '1С',
    }
  },
  // {START} lists
  {
    path: '/one-c/archives',
    name: 'Archives',
    component: () => import('~/views/oneC/lists/Archives.vue'),
    meta: {
      title: 'Архив информационных баз 1С',
    }
  },
  {
    path: '/one-c/profiles',
    name: 'Profiles',
    component: () => import('~/views/oneC/lists/Profiles.vue'),
    meta: {
      title: 'Профили пользователей',
    }
  },  
  // {END} lists
];