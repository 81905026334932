import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "sm-dialog__body" }

import { ref, toRefs, onMounted, nextTick } from 'vue';

// Components
import SmModal from '@/components/common/modal/SmModal.vue';

// Types
import { IDialogProps } from '@/types/components/modal';

// Props


export default /*@__PURE__*/_defineComponent({
  __name: 'SmDialog',
  props: {
    title: { default: '' },
    showCloseIcon: { type: Boolean, default: false },
    showCancelButton: { type: Boolean, default: true },
    showConfirmButton: { type: Boolean, default: true },
    cancelButtonText: { default: 'Отмена' },
    confirmButtonText: { default: 'Ок' },
    cancebleBackdrop: { type: Boolean, default: false },
    text: { default: '' }
  },
  emits: ["cancel", "confirm", "close"],
  setup(__props: any, { emit: __emit }) {

// Modules
const props = __props;

const {
  title,
  showCloseIcon,
  text,
  showCancelButton,
  showConfirmButton,
  cancelButtonText,
  confirmButtonText,
} = toRefs(props);

// Emits

const emits = __emit;

// Data

const showModal = ref<boolean>(false);

// Methods

const cancel = (): void => {
  showModal.value = false;

  setTimeout(() => {
    emits('close');
  }, 300);
}

// Handlers
const handleCancel = (): void => {
  emits('cancel');
  cancel();
}
const handleApply = (): void => {
  emits('confirm');
  cancel();
}

onMounted(() => {
  nextTick(() => {
    showModal.value = true;
  });
});

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createBlock(SmModal, {
    modelValue: showModal.value,
    "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((showModal).value = $event)),
    title: _unref(title),
    "show-close-icon": _unref(showCloseIcon),
    "canceble-backdrop": _ctx.cancebleBackdrop,
    "max-height": "600px",
    "is-left-button": _unref(showCancelButton),
    "is-right-button": _unref(showConfirmButton),
    "left-button-text": _unref(cancelButtonText),
    "right-button-text": _unref(confirmButtonText),
    onLeftButtonClick: handleCancel,
    onRightButtonClick: handleApply
  }, {
    default: _withCtx(() => [
      _createElementVNode("p", _hoisted_1, _toDisplayString(_unref(text)), 1)
    ]),
    _: 1
  }, 8, ["modelValue", "title", "show-close-icon", "canceble-backdrop", "is-left-button", "is-right-button", "left-button-text", "right-button-text"]))
}
}

})