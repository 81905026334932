import { useCssVars as _useCssVars, defineComponent as _defineComponent } from 'vue'
import { unref as _unref, openBlock as _openBlock, createBlock as _createBlock } from "vue"

import { computed, ref, toRefs } from 'vue';

// Components
import SmIcon from '@/components/common/SmIcon/SmIcon.vue';

// Types
import { ILoaderProps } from '@/types/components/loaders';

// Props

export default /*@__PURE__*/_defineComponent({
  __name: 'SmLoader',
  props: {
    size: { default: 16 }
  },
  setup(__props: any) {

_useCssVars(_ctx => ({
  "1273d2a5": (sizeString.value)
}))

// Modules
const props = __props;

const { size } = toRefs(props);

// Computeds
const sizeString = computed((): string => `${props.size}px`);

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createBlock(SmIcon, {
    name: "Loader",
    size: _unref(size),
    class: "sm-loader"
  }, null, 8, ["size"]))
}
}

})