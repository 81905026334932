import { defineStore } from '@pinia';
import { getCurrentUserPermissions as apiGetCurrentUserPermissions,
} from '~/api/permissions';
import { IPermissions } from "@core/types/permissions";
import { ref } from 'vue';

export const usePermissionsStore = defineStore('permissions', () => {

  const permissions = ref<IPermissions>({
    hasFullAccess: false,
    permissions: []
  });
  
  const getCurrentUserPermissions = async () => {
    try {
      const response = await apiGetCurrentUserPermissions();
      permissions.value = response;
    } catch (error) {
      throw(error);
    }
  };

  return {
    permissions,
    getCurrentUserPermissions,
  }
});
