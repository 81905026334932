import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, resolveDynamicComponent as _resolveDynamicComponent, openBlock as _openBlock, createBlock as _createBlock, createElementVNode as _createElementVNode, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = ["aria-labelledby", "width", "height", "viewBox"]
const _hoisted_2 = ["fill"]

import { IIconProps, ISvgBox } from '@/types/components/icons'

import {
  defineAsyncComponent,
  ref,
  computed,
  watch,
} from 'vue';
import { capitalizeFirstLetter } from '@/utils/capitalizeFirstLetter';

// [SART] Props

export default /*@__PURE__*/_defineComponent({
  __name: 'SmIcon',
  props: {
    name: { default: undefined },
    size: { default: 16 },
    color: { default: 'currentColor' },
    viewbox: {}
  },
  setup(__props: any) {

const props = __props;
// [END] Props

// [START] Variables
let icon = ref<HTMLElement | null>(null);

const setIcon = (): Promise<typeof import('*.vue')> => {
  return defineAsyncComponent(async () => {
    return await import(
      `@/components/common/SmIcon/icons/${props.size}/${capitalizeFirstLetter(props.name)}.vue`
    );
  });
};

let iconComponent = setIcon();
// [END] Variables

// [START] Computed
const width = computed((): string => `${props.size}px`);
const height = computed((): string => `${props.size}px`);

const svgBoxSize = computed<ISvgBox>((): ISvgBox => {
  let data: ISvgBox = {
    x: 0,
    y: 0,
    width: 16,
    height: 16
  };

  if (icon.value instanceof SVGGraphicsElement) {
    const svg = icon.value;
    data = svg.getBBox();
  }

  return data;
});

const viewbox = computed((): string => {
  if (props.viewbox) return props.viewbox;

  const size = +props.size;
  const { width, height } = svgBoxSize.value;
  // const computedWidth = width < size ? size : width;
  // const computedHeight = height < size ? size : height;
  const computedWidth = Math.max(width, size);
  const computedHeight = Math.max(height, size);
  return `0 0 ${computedWidth} ${computedHeight}`;
});
// [END] Computed

watch(
  () => props.name,
  (val: IIconProps, oldVal: IIconProps): void => {
    if (val !== oldVal && icon.value) {
      iconComponent = setIcon();
    }
  },
);

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("svg", {
    xmlns: "http://www.w3.org/2000/svg",
    "aria-labelledby": props.name,
    width: width.value,
    height: height.value,
    viewBox: viewbox.value,
    preserveAspectRatio: "xMinYMin meet",
    role: "presentation",
    class: "sm-icon"
  }, [
    _createElementVNode("g", {
      fill: props.color,
      ref: "iconWrap"
    }, [
      (_openBlock(), _createBlock(_resolveDynamicComponent(_unref(iconComponent)), {
        ref_key: "icon",
        ref: icon
      }, null, 512))
    ], 8, _hoisted_2)
  ], 8, _hoisted_1))
}
}

})