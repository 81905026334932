import { RouteRecordRaw } from 'vue-router';

export const routes: Array<RouteRecordRaw> = [
  // {START} lists
  {
    path: '/meetings',
    name: 'Meetings',
    component: () => import('~/views/meetings/lists/Meetings.vue'),
    meta: {
      title: 'Собрание собственников',
    }
  }, 
  // {END} lists
];